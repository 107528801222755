import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MeetingVideoImg from "./../assets/img/teams_icon_2.svg";
import MeetingGroupImg from "./../assets/img/person-filled.svg";
import MeetingZoomImg from "./../assets/img/zoom_icon.png";

export const isMacApp = window.isMacApp;
export const isIOSApp = window.isIOS;
export const isAndroidApp = window.AndroidInterface;

export function calculateAge(dob: string): number {
  const today = new Date();
  const birthDate = new Date(dob);

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  // If birth date month is greater than current month or
  // if birth date month is equal to current month but birth date day is greater than current day,
  // then decrement age by 1
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
}

export function formatDateTime(dateTimeString: string): {
  date: string;
  time: string;
} {
  if (!dateTimeString) return { date: "", time: "" };
  const date = new Date(dateTimeString);
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric"
  };
  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "2-digit",
    hour12: true
  };

  const formattedDate = date.toLocaleDateString("en-US", dateOptions);
  const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

  return { date: formattedDate, time: formattedTime };
}

export function arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}
export const isExtension = () => false;
export const CHROME_EXTENSION_ID = "jlgoggfmcekdchijpnbfofeddedejnnp";
export const isChromeExtension = () => {
  return chromeVariableAvailable() && chrome.runtime && chrome.runtime.id;
};
// window.location.protocol === "chrome-extension:";
export const isSmallDevice = () =>
  window.matchMedia("(max-width: 820px)").matches;
export const isMobile = () => isSmallDevice() && !isExtension();

export const injectMicrophonePermissionIframe = () => {
  // const iframe = document.createElement("iframe");
  // iframe.setAttribute("hidden", "hidden");
  // iframe.setAttribute("id", "permissionsIFrame");
  // iframe.setAttribute("allow", "microphone");
  // iframe.src = chrome.runtime.getURL("permission/index.html");
  // document.body.appendChild(iframe);

  navigator.mediaDevices.getUserMedia({ audio: true }).catch((err) => {
    console.error(err);

    if (chromeVariableAvailable()) {
      chrome.tabs.create({
        url: chrome.runtime.getURL("permission/index.html"),
        active: true
      });
    }
  });
};

export const validateEmail = (email: string): boolean => {
  // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const emailRegex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return emailRegex.test(email);
};

export const validateOtp = (otp: string): boolean => {
  const otpRegex = /^\d{1,6}$/;
  return otpRegex.test(otp);
};

export const getMeetingHtmlContent = ({
  organizationDetails,
  meetingDetails,
  notesHtml,
  summaryHtml,
  convertTo
}) => {
  const { date, time } = formatDateTime(meetingDetails.start_time);
  const firmParticipants = meetingDetails.firm_side_participants
    .map((i, index) => (index === 0 ? "" : ", ") + `${i.name}`)
    .join("");
  const clients = meetingDetails.client_side_participants
    .map((i) => `${i.name}`)
    .join(", ");

  let default_font_color = "#000";
  let default_line_height = 1.5;
  let default_font_family = "Calibri, 'Open Sans', serif !important";
  let default_font_size = "12px";
  let headings_font_color = "#000";
  let default_font_family_link = `<link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap" rel="stylesheet">`;

  let default_font_details =
    organizationDetails?.organization?.default_font_details;

  if (default_font_details) {
    if (default_font_details.default_font_color) {
      default_font_color = default_font_details.default_font_color;
    }

    if (default_font_details.default_line_height) {
      default_line_height = default_font_details.default_line_height;
    }
    if (default_font_details.default_font_size) {
      default_font_size = default_font_details.default_font_size;
    }

    if (default_font_details.default_font_family) {
      default_font_family = default_font_details.default_font_family;
    }

    if (default_font_details.headings_font_color) {
      headings_font_color = default_font_details.headings_font_color;
    }

    if (default_font_details.default_font_family_link) {
      default_font_family_link = default_font_details.default_font_family_link;
    }
  }

  //    ${organizationDetails?.organization?.footer_html ? organizationDetails.organization.footer_html : ''}

  return `
   <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  ${default_font_family_link}
  <style>
    p{margin:0px; font-size:${default_font_size};}
    ul{list-style-type: disc; line-height: 1.3;}
    p > span:first-of-type[data-text-color='blue']{
      font-weight: 700;
      font-size: 14px;
      color: #2B2B2B;
    }
  </style>
  <div style="position: relative; overflow: hidden;">
  <div style="font-family: ${default_font_family}; line-height: ${default_line_height}; color: ${default_font_color}; position: relative; z-index: 1;">
    ${
      organizationDetails?.organization?.header_html
        ? organizationDetails.organization.header_html
        : ""
    }
    <h2 style="color: ${headings_font_color}; margin-bottom: 4px; font-size: 20px; margin-top: 40px;"><strong>${meetingDetails.title}</strong></h2>
    <p style="font-size: ${default_font_size}; margin-bottom: 2px;">
      <strong>Date:</strong> ${date} | ${time}
    </p>
    <p style="font-size: ${default_font_size}; margin-bottom:24px;">
      <strong>Attendees:</strong> ${firmParticipants}${
        clients ? ", " + clients : ""
      }
    </p>
    ${
      summaryHtml
        ? `
    <div style="margin-bottom: 40px;">
      <h3 style="color: ${headings_font_color}; margin-bottom: 12px; font-size: 16px;"><strong>Summary</strong></h3>
      <div style="padding-left: 10px; padding-right: 10px; padding-top: 0px; padding-bottom: 0px; border-left: 1px solid ${headings_font_color};">
        ${summaryHtml}
      </div>
    </div>
    `
        : ""
    }
    ${
      notesHtml
        ? `
    <div style="margin-bottom: 40px;">
      <h3 style="color: ${headings_font_color}; margin-bottom: 12px; font-size: 16px;"><strong>Notes</strong></h3>
      <div style="padding-left: 10px; padding-right: 10px; padding-top: 0px; padding-bottom: 0px; border-left: 1px solid ${headings_font_color};">
        ${notesHtml}
      </div>
    </div>
    `
        : ""
    }
  </div>
  </div>
  `;
};

export function compareVersions(v1, v2) {
  const v1Parts = v1.split(".").map(Number);
  const v2Parts = v2.split(".").map(Number);

  for (let i = 0; i < 3; i++) {
    if (v1Parts[i] < v2Parts[i]) {
      return -1; // v1 is less than v2
    }
    if (v1Parts[i] > v2Parts[i]) {
      return 1; // v1 is greater than v2
    }
  }

  return 0; // v1 is equal to v2
}

export const hardReload = () => {
  // Store the current URL without any parameters
  const originalURL = window.location.href.split("?")[0];

  // Append a timestamp to the URL to force a reload
  const newURL = `${originalURL}?timestamp=${new Date().getTime()}`;

  // Change the URL to the one with the timestamp to bypass the cache
  window.location.href = newURL;

  // Use replaceState to clean the URL immediately after reload
  window.history.replaceState(null, "", originalURL);
};

export function generateUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function secondsToMinutes(seconds: number): string {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
}

export const compose = (...classes) => {
  if (!classes || !classes.length) {
    return "";
  }
  return classes.filter(Boolean).join(" ");
};

const getOrdinal = function (number, appendWithNumber) {
  appendWithNumber =
    typeof appendWithNumber !== "undefined" ? appendWithNumber : true;
  const s = ["th", "st", "nd", "rd"],
    v = number % 100;
  return (appendWithNumber ? number : "") + (s[(v - 20) % 10] || s[v] || s[0]);
};

const digitPercisionString = function (digit, point) {
  digit = digit.toString();
  if (digit.length < point) {
    let pre = "";
    for (let i = digit.length; i < point; i++) {
      pre += "0";
    }
    digit = pre + digit;
  } else if (digit.length > point) {
    digit = parseFloat(digit).toPrecision(point);
  }
  return digit;
};

export const MONTH_NAMES = [
  { short: "Jan", long: "January" },
  { short: "Feb", long: "February" },
  { short: "Mar", long: "March" },
  { short: "Apr", long: "April" },
  { short: "May", long: "May" },
  { short: "Jun", long: "June" },
  { short: "Jul", long: "July" },
  { short: "Aug", long: "August" },
  { short: "Sep", long: "September" },
  { short: "Oct", long: "October" },
  { short: "Nov", long: "November" },
  { short: "Dec", long: "December" }
];

export const WEEK_DAYS = {
  shortForm: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  longForm: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ]
};

export const parseFormatDate = function (dateTime) {
  dateTime = parseInt(dateTime, 10);
  if (!dateTime || dateTime < 0) {
    return;
  }
  let obj = {};
  //DD/MM/YYYY HH:min TT
  const date = new Date(dateTime);
  obj["dd"] = date.getDate();
  obj["dayLong"] = WEEK_DAYS.longForm[date.getDay()];
  obj["dayShort"] = WEEK_DAYS.shortForm[date.getDay()];
  obj["ord"] = getOrdinal(date.getDate(), false);
  obj["MM"] = MONTH_NAMES[date.getMonth()].long;
  obj["mmm"] = MONTH_NAMES[date.getMonth()].short;
  obj["mm"] = digitPercisionString(date.getMonth() + 1, 2);
  obj["yyyy"] = date.getFullYear();
  obj["yy"] = date.getFullYear().toString().substr(2);
  obj["hrs"] = date.getHours();
  obj["hrh"] = date.getHours() === 12 ? 12 : date.getHours() % 12;
  obj["min"] = digitPercisionString(date.getMinutes(), 2);
  obj["sec"] = digitPercisionString(date.getSeconds(), 2);
  obj["mdnC"] = date.getHours() >= 12 ? "PM" : "AM";
  obj["mdn"] = date.getHours() >= 12 ? "pm" : "am";
  return obj;
};

export const timeRemainingAsObject = (milliseconds, skipDays) => {
  milliseconds = parseInt(milliseconds);
  var seconds = parseInt((milliseconds / 1000) % 60);
  var minutes = parseInt((milliseconds / (1000 * 60)) % 60);
  var hours = 0;
  var days = 0;
  if (skipDays) {
    hours = parseInt(milliseconds / (1000 * 60 * 60));
  } else {
    hours = parseInt((milliseconds / (1000 * 60 * 60)) % 24);
    days = parseInt(milliseconds / (1000 * 60 * 60) / 24);
  }

  let timeRemainingObject = {};
  timeRemainingObject["days"] = days;
  timeRemainingObject["hours"] = hours;
  timeRemainingObject["minutes"] = digitPercisionString(minutes, 2);
  timeRemainingObject["seconds"] = digitPercisionString(seconds, 2);

  return timeRemainingObject;
};

export const getMeetingImgType = (meeting_location) => {
  switch (meeting_location) {
    case "Microsoft Teams":
      return MeetingVideoImg;
    case "Google Meet":
      return MeetingVideoImg;
    case "Zoom":
      return MeetingZoomImg;
    default:
      return MeetingGroupImg;
  }
};

export const isBrowser = () => {
  return typeof window !== "undefined";
};

export const chromeVariableAvailable = () => {
  return typeof chrome !== "undefined";
};

export const getDeviceType = () => {
  return isBrowser()
    ? window.AndroidInterface
      ? "ANDROID"
      : window.isMacApp
        ? "MAC"
        : window.isIOS
          ? "IOS"
          : window.startRecording
            ? "WINDOWS"
            : "WEB"
    : "SERVER";
};

export const timezoneForBE = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const CRM_LOGOS = {
  OUTLOOK: "https://assets.getmili.ai/images/icon/outlook.png",
  XLR8: "https://assets.getmili.ai/images/icon/xlr8.jpeg",
  SALESFORCE: "https://assets.getmili.ai/images/icon/salesforce-logo.png",
  SALENTICA: "https://assets.getmili.ai/images/icon/Salentica_New.png",
  WEALTHBOX: "https://assets.getmili.ai/images/icon/wealthbox-logo.png",
  PRACTIFI: "https://assets.getmili.ai/images/icon/Practifi_White.png",
  SFC: "https://assets.getmili.ai/images/icon/salesforce-logo.png"
};

export const getSyncBoxLastSyncText = (syncTime) => {
  if (!syncTime) {
    return null;
  }

  const syncTimeInDateObj = new Date(syncTime);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  if (syncTimeInDateObj.getTime() >= today.getTime()) {
    return `today ${syncTimeInDateObj.toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true // 12-hour format with AM/PM
    })}`;
  } else if (syncTimeInDateObj.getTime() >= today.getTime() - 86400000) {
    return `yesterday ${syncTimeInDateObj.toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true // 12-hour format with AM/PM
    })}`;
  } else {
    return `${syncTimeInDateObj.toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true // 12-hour format with AM/PM
    })}`;
  }
};

export const markdownToPlainTextBeautify = (markdown) => {
  if (!markdown) {
    return "";
  }

  return markdown
    .replace(/\*\*/g, "")
    .replace(/\\</g, "<")
    .replace(/\\&/g, "&")
    .replace(/\\\n/g, "\n")
    .replace(/\\_/g, "_")
    .replace(/\n\*\s+/g, (match) => {
      return match.replace("*", "-");
    })
    .replace(/\n\n\-{1}/g, "\n-")
    .replace(/\n\n\s{1}/g, "\n");
};

export const sendToNativeApps = (type, data = {}) => {
  const message = JSON.stringify({ type, data });

  if (window.AndroidInterface && window.AndroidInterface?.receiveMessage) {
    // Call a single method in Android that receives JSON
    window.AndroidInterface.receiveMessage(message);
  } else if (
    (window.isIOS || window.isMacApp) &&
    window.webkit?.messageHandlers?.iosListener
  ) {
    // Send the same JSON to iOS
    window.webkit.messageHandlers.iosListener.postMessage(message);
  } else {
    console.error("Native interface not found.");
  }
};

export const CRMS = {
  ADVYZON: "advyzon"
};

export const isLoggedIn = (userDetails) => {
  return userDetails?.user_id;
};

export const EXTENSION_CTA_STATES_LIST = {
  SYCNING: "SYCNING",
  WAITING_FOR_LOGIN: "WAITING_FOR_LOGIN",
  COMPLETE: "COMPLETE",
  RETRY: "RETRY",
  PAUSED: "PAUSED"
};

export const sortArrayAlphabeticallyWithCaseInsensitive = (
  arr,
  keyName = null
) => {
  if (keyName) {
    arr.sort((a, b) => a[keyName].localeCompare(b[keyName]));
  } else {
    arr.sort((a, b) => a.localeCompare(b));
  }
  return arr;
};
