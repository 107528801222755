import { Box, ButtonBase, CircularProgress } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import ColorsConst from "../../utils/ColorsConst";
import M3LabelLargeTypography from "../customTypography/M3LabelLargeTypography";
import M3TitleMedium from "../customTypography/M3TitleMedium";
import M3BodyMediumTypography from "../customTypography/M3BodyMedium";
import ReplayIcon from "@mui/icons-material/Replay";
import M3BodySmall from "../customTypography/M3BodySmall";
import { useEffect, useRef, useState } from "react";
// import { toastEmitter } from "../toast/toastManager";
import { get } from "../../api";
import { API_BASE_URL, ROUTES } from "../../api/config";
import Mixpanel, { EventNames } from "../../utils/analytics/mixpanel";
import { useDeviceStatus } from "../../hooks/useDeviceStatus";
import SyncIcon from "@mui/icons-material/Sync";
import { toastEmitter } from "../toast/toastManager";
import { getSyncBoxLastSyncText } from "../../utils/Util";

const ThirdPartyIntegrationsCard = (props) => {
  const [connectSuccessful, setConnectSuccessful] = useState(false);
  const [connectInProgress, setConnectInProgress] = useState(false);
  const [connectFailed, setConnectFailed] = useState(false);
  const recheckCounterRef = useRef(0);
  const isMounted = useRef(false);
  const syncTimeoutRef = useRef(null);
  const checkSyncAPIRef = useRef(0);
  const [syncStatusObj, setSyncStatusObj] = useState(null);

  const { isSmallDevice } = useDeviceStatus();

  const selected = props.name === props.selectedName;

  const CONTACT_SYNC_LIST = {
    WEALTHBOX: {
      SYNC: ROUTES.SYNC_WEALTHBOX_CONTACTS,
      SYNC_STATUS: ROUTES.SYNC_STATUS_WEALTHBOX_CONTACTS
    },
    XLR8: {
      SYNC: ROUTES.SYNC_XLR8_ENTITIES,
      SYNC_STATUS: ROUTES.SYNC_STATUS_XLR8_ENTITIES
    }
    // PRACTIFI: {
    //   SYNC: ROUTES.SYNC_PRACTIFI_ENTITIES,
    //   SYNC_STATUS: ROUTES.SYNC_STATUS_PRACTIFI_ENTITIES
    // },
    // SALESFORCE: {
    //   SYNC: ROUTES.SYNC_SALESFORCE_ENTITIES,
    //   SYNC_STATUS: ROUTES.SYNC_STATUS_SALESFORCE_ENTITIES
    // },
    // SFC: {
    //   SYNC: ROUTES.SYNC_SALESFORCE_FINANCIAL_CLOUD_ENTITIES,
    //   SYNC_STATUS: ROUTES.SYNC_STATUS_SALESFORCE_FINANCIAL_CLOUD_ENTITIES
    // },
    // SALENTICA: {
    //   SYNC: ROUTES.SYNC_SALENTICA_ENTITIES,
    //   SYNC_STATUS: ROUTES.SYNC_STATUS_SALENTICA_ENTITIES
    // }
  };

  const syncOptionEnabled = CONTACT_SYNC_LIST[props.type];
  const timeoutVal = 5000;

  const fetchSyncStatus = async () => {
    if (syncOptionEnabled && isMounted.current) {
      checkSyncAPIRef.current = checkSyncAPIRef.current + 1;

      if (checkSyncAPIRef.current >= 300) {
        toastEmitter.show({
          message: "Entities and contacts sync failed, please try again",
          severity: "error"
        });
        setSyncStatusObj((prev) => {
          return { ...prev, status: "failed" };
        });
        return;
      }
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const res = await get(syncOptionEnabled.SYNC_STATUS);
          setSyncStatusObj((prev) => {
            return { ...prev, ...res };
          });
          if (res?.status === "running") {
            clearTimeout(syncTimeoutRef.current);
            syncTimeoutRef.current = setTimeout(() => {
              fetchSyncStatus();
            }, timeoutVal);
          }
        } catch (e) {
          console.error("fetchSyncStatus", props.type, e);
        }
      }
    }
  };

  const startSync = async () => {
    try {
      if (syncStatusObj?.status == "running" || !syncOptionEnabled) {
        return;
      }

      const token = localStorage.getItem("token");
      if (token) {
        setSyncStatusObj((prev) => {
          return {
            ...prev,
            status: "running",
            total_entities_synced: 0,
            total_contacts_synced: 0
          };
        });
        clearTimeout(syncTimeoutRef.current);
        await get(syncOptionEnabled.SYNC);

        checkSyncAPIRef.current = 0;
        syncTimeoutRef.current = setTimeout(() => {
          fetchSyncStatus();
        }, timeoutVal);
      }
    } catch (e) {
      setSyncStatusObj((prev) => {
        return { ...prev, status: "failed" };
      });
    }
  };

  useEffect(() => {
    isMounted.current = true;
    fetchSyncStatus();

    return () => {
      isMounted.current = false;
    };
  }, []);

  const onConnectClicked = (type) => {
    Mixpanel.track(EventNames.BUTTON_CLICKED, {
      Screen: window.location.pathname,
      Section: props.section,
      "Button Name": type,
      "Relevant Content": { Tool: props.name }
    });

    if (connectInProgress) {
      return;
    }

    setConnectFailed(false);
    const token = localStorage.getItem("token");
    if (token && !connectInProgress) {
      window.open(`${API_BASE_URL}${props.oauth_url}?token=${token}`, "_blank");
      setConnectInProgress(true);
    }
  };

  const checkConnected = async () => {
    recheckCounterRef.current = recheckCounterRef.current + 1;

    if (recheckCounterRef.current >= 60) {
      // toastEmitter.show({
      // 	message: "Could not connect. Please retry",
      // 	severity: "error",
      // });
      setConnectInProgress(false);
      setConnectFailed(true);

      Mixpanel.track(EventNames.INTEGRATION_ATTEMPT, {
        "Modal Name": "Integration Connect",
        "Modal Type": "Connect Modal",
        Screen: window.location.pathname,
        Section: props.section,
        Result: "Failure",
        Tool: props.name,
        "Reason for failure": "Max retries exceeded"
      });
      return;
    }

    const token = localStorage.getItem("token");
    if (token) {
      try {
        const res = await get(`${API_BASE_URL}${props.exists_url}`);
        if (res.exists) {
          setConnectSuccessful(true);
          setConnectInProgress(false);
          if (props?.onSuccess && selected) {
            setTimeout(() => {
              props?.onSuccess?.();
            }, 2000);
          }
          Mixpanel.track(EventNames.INTEGRATION_ATTEMPT, {
            "Modal Name": "Integration Connect",
            "Modal Type": "Connect Modal",
            Screen: window.location.pathname,
            Section: props.section,
            Result: "Success",
            Tool: props.name
          });
          // toastEmitter.show({
          // 	message: "Connected successful",
          // 	severity: "success",
          // });
        } else {
          setTimeout(() => {
            checkConnected();
          }, 5000);
        }
      } catch (e) {
        Mixpanel.track(EventNames.INTEGRATION_ATTEMPT, {
          "Modal Name": "Integration Connect",
          "Modal Type": "Connect Modal",
          Screen: window.location.pathname,
          Section: props.section,
          Result: "Failure",
          Tool: props.name,
          "Reason for failure": e.message
        });
        console.error("integration connect card", e);
      }
    }
  };

  useEffect(() => {
    if (connectInProgress) {
      recheckCounterRef.current = 0;
      checkConnected();
    }
  }, [connectInProgress]);

  const renderBtn = () => {
    if (connectInProgress) {
      return (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            p: isSmallDevice ? "10px 0px" : "12px 0px",
            bgcolor: "#E6E0E91F",
            width: isSmallDevice ? "120px" : "148px",
            borderRadius: "100px"
          }}
        >
          <CircularProgress
            size={isSmallDevice ? 14 : 20}
            sx={{ color: ColorsConst.ON_SURFACE, opacity: 0.38, mr: "8px" }}
          />
          <M3LabelLargeTypography
            sx={{ color: ColorsConst.ON_SURFACE, opacity: 0.38 }}
          >
            Connecting
          </M3LabelLargeTypography>
        </Box>
      );
    }

    if (connectFailed) {
      return (
        <Box
          display={"flex"}
          flexDirection={"column"}
          // justifyContent={"flex-end"}
          alignItems={"flex-end"}
        >
          <ButtonBase
            onClick={() => onConnectClicked("RETRY")}
            sx={{
              p: isSmallDevice ? "10px" : "12px",
              bgcolor: ColorsConst.DARK_PRIMARY,
              width: isSmallDevice ? "120px" : "148px",
              borderRadius: "100px"
            }}
          >
            <ReplayIcon
              sx={{
                transform: "scaleX(-1)",
                color: ColorsConst.ON_PRIMARY,
                height: isSmallDevice ? "14px" : "18px",
                width: isSmallDevice ? "14px" : "18px",
                mr: "8px"
              }}
            />
            <M3LabelLargeTypography
              color={ColorsConst.ON_PRIMARY}
              fontWeight={600}
            >
              Retry
            </M3LabelLargeTypography>
          </ButtonBase>
          <M3BodySmall
            color={ColorsConst.ERROR}
            sx={{ mt: isSmallDevice ? "4px" : "8px" }}
          >
            Could not connect. Please retry
          </M3BodySmall>
        </Box>
      );
    }

    if (connectSuccessful || props.status == "CONNECTED") {
      return (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            color: ColorsConst.DARK_PRIMARY,
            width: isSmallDevice ? "120px" : "148px"
          }}
        >
          <DoneIcon sx={{ height: "18px", width: "18px", mr: "8px" }} />
          <M3LabelLargeTypography color={ColorsConst.DARK_PRIMARY}>
            Connected
          </M3LabelLargeTypography>
        </Box>
      );
    }

    switch (props.status) {
      case "NOT_CONNECTED":
        return (
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"flex-end"}
          >
            <ButtonBase
              onClick={() => onConnectClicked("CONNECT")}
              sx={{
                p: isSmallDevice ? "10px" : "12px",
                bgcolor: ColorsConst.DARK_PRIMARY,
                width: isSmallDevice ? "120px" : "148px",
                borderRadius: "100px"
              }}
            >
              <M3LabelLargeTypography
                color={ColorsConst.ON_PRIMARY}
                fontWeight={600}
              >
                Connect
              </M3LabelLargeTypography>
            </ButtonBase>
            {selected && (
              <M3BodySmall
                color={ColorsConst.ON_SURFACE}
                sx={{ mt: isSmallDevice ? "3px" : "6px", textAlign: "center" }}
              >
                Connect this to proceed
              </M3BodySmall>
            )}
          </Box>
        );

      case "UPCOMING":
        return (
          <Box
            sx={{
              p: isSmallDevice ? "10px 0px" : "12px 0px",
              bgcolor: "#E6E0E91F",
              width: isSmallDevice ? "120px" : "148px",
              borderRadius: "100px",
              textAlign: "center"
            }}
          >
            <M3LabelLargeTypography sx={{ opacity: 0.38 }}>
              Upcoming
            </M3LabelLargeTypography>
          </Box>
        );

      default:
        return null;
    }
  };

  const connectedSuccess = connectSuccessful || props.status == "CONNECTED";

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      mb="24px"
      borderRadius={"8px"}
      position={"relative"}
      sx={{
        ...(selected && {
          bgcolor: "#3C3846",
          p: "12px 20px",
          width: isSmallDevice ? "calc(100% + 32px)" : "calc(100% + 40px)",
          left: isSmallDevice ? "-16px" : "-20px"
        })
      }}
    >
      <Box
        mr={isSmallDevice ? "8px" : "16px"}
        borderRadius={"8px"}
        overflow={"hidden"}
        flexShrink={0}
      >
        <img
          src={props.icon_url}
          height={isSmallDevice ? 24 : 40}
          width={isSmallDevice ? 24 : 40}
          style={{ display: "block", objectFit: "contain" }}
        />
      </Box>
      <Box maxWidth={"60%"} mr="16px">
        <M3TitleMedium mb={isSmallDevice ? "0px" : "4px"}>
          {props.name}
        </M3TitleMedium>
        {connectedSuccess && syncStatusObj?.status ? (
          <Box>
            {syncOptionEnabled &&
              (syncStatusObj?.status === "success" ||
                syncStatusObj?.status === "failed") && (
                <Box display={"flex"} alignItems={"center"}>
                  <SyncIcon
                    sx={{
                      mr: "8px",
                      color: ColorsConst.DARK_PRIMARY,
                      height: "16px",
                      width: "16px"
                    }}
                  />
                  <ButtonBase onClick={startSync}>
                    <M3BodyMediumTypography
                      mr="16px"
                      color={ColorsConst.DARK_PRIMARY}
                    >
                      Sync Accounts
                    </M3BodyMediumTypography>
                  </ButtonBase>
                  {syncStatusObj?.last_successful_sync && (
                    <M3BodyMediumTypography color={ColorsConst.DARK_OUTLINE}>
                      Last synced{" "}
                      {getSyncBoxLastSyncText(
                        syncStatusObj.last_successful_sync
                      )}
                    </M3BodyMediumTypography>
                  )}
                </Box>
              )}
            {syncOptionEnabled && syncStatusObj?.status === "running" && (
              <Box display={"flex"} alignItems={"center"}>
                <M3BodyMediumTypography color={ColorsConst.DARK_PRIMARY}>
                  <CircularProgress
                    sx={{
                      color: ColorsConst.DARK_PRIMARY,
                      mr: "8px",
                      height: "12px !important",
                      width: "12px !important"
                    }}
                  />
                  Syncing:
                </M3BodyMediumTypography>
                &nbsp;
                <M3BodyMediumTypography color={ColorsConst.DARK_OUTLINE}>
                  {syncStatusObj?.total_entities_synced ||
                    syncStatusObj?.total_contacts_synced ||
                    0}{" "}
                  accounts
                </M3BodyMediumTypography>
              </Box>
            )}
          </Box>
        ) : (
          <Box>
            <M3BodyMediumTypography color={ColorsConst.DARK_OUTLINE}>
              {connectSuccessful || props.status == "CONNECTED"
                ? props.success_message || props.description
                : props.description}
            </M3BodyMediumTypography>
          </Box>
        )}
      </Box>
      <Box ml="auto">{renderBtn()}</Box>
    </Box>
  );
};

export default ThirdPartyIntegrationsCard;
