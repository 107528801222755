import React from "react";
import ReactDOM from "react-dom/client"; // Import from 'react-dom/client' in React 18
import WifiOffIcon from "@mui/icons-material/WifiOff";
import wifiGif from "./assets/img/wifi.gif";

// Object to store preloaded icons and images
export const preloadedAssets = {
  icons: {
    WifiOff: WifiOffIcon,
    // Add other icons as needed
  },
  images: {
    wifi: wifiGif,
    // Add other images as needed
  },
};

// Function to preload images and icons
export const preloadAssets = () => {
  try {
    // Preload images
    Object.values(preloadedAssets.images).forEach((src) => {
      const img = new Image();
      img.src = src;
      console.log(`Preloaded image: ${src}`);
    });

    // Preload icons by rendering them into hidden containers using createRoot
    Object.keys(preloadedAssets.icons).forEach((iconName) => {
      const IconComponent = preloadedAssets.icons[iconName];
      const container = document.createElement("div");
      container.style.display = "none"; // Hide the container
      document.body.appendChild(container);
      const root = ReactDOM.createRoot(container); // Create a root
      root.render(<IconComponent />); // Render the icon
      console.log(`Preloaded icon: ${iconName}`);
    });
  } catch (error) {
    console.error("Error preloading assets:", error);
  }
};

// Helper function to create icon element
export const createIconElement = (iconName: string, props: any) => {
  const IconComponent = preloadedAssets.icons[iconName];
  if (!IconComponent) {
    console.warn(`Icon "${iconName}" not found in preloadedAssets.`);
    return null;
  }
  return <IconComponent {...props} />;
};

// Helper function to get image URL
export const getImageUrl = (imageName: string) => {
  const imageUrl = preloadedAssets.images[imageName];
  if (!imageUrl) {
    console.warn(`Image "${imageName}" not found in preloadedAssets.`);
    return "";
  }
  return imageUrl;
};
