import { useEffect, useRef, useState } from "react";
import { toastEmitter } from "../../components/toast/toastManager";
import Loader from "../../components/loader";
import {
  chromeVariableAvailable,
  CRMS,
  EXTENSION_CTA_STATES_LIST
} from "../../utils/Util";
import AdvyzonExtension from "../meetingDetails/components/Advyzon/AdvyzonExtension";
import Mixpanel, { EventNames } from "../../utils/analytics/mixpanel";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, ButtonBase, Popover, Typography } from "@mui/material";
import ColorsConst from "../../utils/ColorsConst";
import M3TitleLarge from "../../components/customTypography/M3TitleLarge";
import CloseButton from "../../components/closeButton/CloseButton";
import M3LabelLargeTypography from "../../components/customTypography/M3LabelLargeTypography";
import { post } from "../../api";
import { ROUTES } from "../../api/config";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import M3BodyMediumTypography from "../../components/customTypography/M3BodyMedium";
import styles from "./ExtensionUI.module.scss";
const ExtensionUI = () => {
  const meetingIdRef = useRef();
  const [crmType, setCrmType] = useState(null);
  const [crmSyncData, setCrmSyncData] = useState(null);
  const [ctaState, setCtaState] = useState("");
  const [showReportIssueForm, setShowReportIssueForm] = useState(false);
  const [formDesc, setFormDesc] = useState("");
  const [reportIssueApiState, setReportIssueApiState] = useState("");
  const [dataUpdate, setDataUpdate] = useState(false);

  const pageLoggedinStateIntervalRef = useRef(null);

  const keepAlivePingPongIntervalRef = useRef(null);

  const currentTaskData = useRef(null);

  const messageListener = (message, sender, sendResponse) => {
    if (message.action === "error") {
      console.error(message.err);
    } else if (message.action === "openSidePanel") {
      setDataUpdate((prev) => !prev);
    } else if (message.action === "processStarted") {
      Mixpanel.track(EventNames.EXTENSION_TASK, {
        "Crm Type": crmType,
        Status: "Started",
        Section: "Extension"
      });
    } else if (message.action === "AutoActionTriggered") {
      Mixpanel.track(EventNames.EXTENSION_TASK, {
        "Crm Type": crmType,
        Action: message.action,
        Type: message.type
      });
    } else if (message.action === "directTaskStatusUpdate") {
      if (message.currentTaskData.syncStatus === "FAILED") {
        console.error(message.err);
      }
      currentTaskData.current = {
        ...message.currentTaskData,
        type: message.type
      };
      if (message.type === "NOTE") {
        setCrmSyncData((prev) => {
          return {
            ...prev,
            notes: prev.notes.map((d) => {
              if (d.id === message.currentTaskData.id) {
                return message.currentTaskData;
              }
              return d;
            })
          };
        });
        Mixpanel.track(EventNames.EXTENSION_TASK, {
          "Crm Type": crmType,
          "Action Type": "NOTE",
          Action: "directTaskStatusUpdate",
          Type: message.currentTaskData.syncStatus,
          Data: message.currentTaskData
        });
      } else if (message.type === "ACTION_ITEM") {
        setCrmSyncData((prev) => {
          return {
            ...prev,
            actionItems: prev.actionItems.map((d) => {
              if (d.id === message.currentTaskData.id) {
                return message.currentTaskData;
              }
              return d;
            })
          };
        });
        Mixpanel.track(EventNames.EXTENSION_TASK, {
          "Crm Type": crmType,
          "Action Type": "ACTION_ITEM",
          Action: "directTaskStatusUpdate",
          Type: message.currentTaskData.syncStatus,
          Data: message.currentTaskData
        });
      }
    } else if (message.action === "processCompleted") {
      const anyFailed =
        crmSyncData?.notes?.find((d) => d.syncStatus === "FAILED") ||
        crmSyncData?.actionItems?.find((d) => d.syncStatus === "FAILED");
      const anyPaused =
        crmSyncData?.notes?.find((d) => d.syncStatus === "PAUSED") ||
        crmSyncData?.actionItems?.find((d) => d.syncStatus === "PAUSED");
      if (anyFailed) {
        setCtaState(EXTENSION_CTA_STATES_LIST.RETRY);
      } else if (anyPaused) {
        setCtaState(EXTENSION_CTA_STATES_LIST.PAUSED);
      } else {
        setCtaState(EXTENSION_CTA_STATES_LIST.COMPLETE);
        toastEmitter.show({
          message: "Sync complete. You can close this window now",
          severity: "success"
        });
      }
    } else if (message.action === "processInterupted") {
      setCtaState(EXTENSION_CTA_STATES_LIST.PAUSED);
    } else if (message.action === "tabClosed") {
      setCtaState(EXTENSION_CTA_STATES_LIST.PAUSED);
      console.log(currentTaskData.current, "currentTaskData.current");
      if (currentTaskData.current) {
        Mixpanel.track(EventNames.EXTENSION_TASK, {
          "Crm Type": crmType,
          "Action Type": "NOTE",
          Action: "tabClosed"
        });
        if (currentTaskData.current.type === "NOTE") {
          setCrmSyncData((prev) => {
            return {
              ...prev,
              notes: prev.notes.map((d) => {
                if (d.id === currentTaskData.current.id) {
                  return { ...currentTaskData.current, syncStatus: "PAUSED" };
                }
                return d;
              })
            };
          });
        } else if (currentTaskData.current.type === "ACTION_ITEM") {
          setCrmSyncData((prev) => {
            return {
              ...prev,
              actionItems: prev.actionItems.map((d) => {
                if (d.id === currentTaskData.current.id) {
                  return { ...currentTaskData.current, syncStatus: "PAUSED" };
                }
                return d;
              })
            };
          });
        }
      }
    }
  };

  const startTriggerExtensionSyncFlow = () => {
    if (chromeVariableAvailable()) {
      chrome?.runtime?.sendMessage({
        action: "startDirectTask",
        data: crmSyncData
      });
    }
  };

  useEffect(() => {
    if (!showReportIssueForm && reportIssueApiState) {
      setReportIssueApiState("");
    }
  }, [showReportIssueForm]);

  const getPageLoggedinState = () => {
    if (chromeVariableAvailable()) {
      pageLoggedinStateIntervalRef.current = setInterval(() => {
        chrome?.runtime?.sendMessage(
          { action: "getPageLoggedinState" },
          (resp) => {
            if (resp?.value === "LOGGED_IN") {
              clearInterval(pageLoggedinStateIntervalRef.current);
              setCtaState(EXTENSION_CTA_STATES_LIST.SYCNING);
              Mixpanel.track(EventNames.API_CALL, {
                "Crm Type": crmType,
                Status: "Success",
                "API Name": "Crm LoggedIn",
                Section: "Extension"
              });
            } else if (resp?.value === "NOT_LOGGED_IN") {
              setCtaState(EXTENSION_CTA_STATES_LIST.WAITING_FOR_LOGIN);
            }
          }
        );
      }, 200);
    }
  };

  useEffect(() => {
    if (chromeVariableAvailable()) {
      chrome?.runtime?.sendMessage({ action: "sendCrmData" }, (resp) => {
        if (!resp) return;
        meetingIdRef.current = resp.meetingId;
        const tempSyncData = resp.syncData;
        if (!tempSyncData) return;
        tempSyncData.notes = [
          {
            ...(tempSyncData.note || {}),
            type: "NOTE"
          }
        ];
        tempSyncData.actionItems = (tempSyncData.action_items || []).map(
          (d) => {
            return { ...d, type: "ACTION_ITEM" };
          }
        );
        setCrmSyncData(tempSyncData);
        setCrmType(resp.crmType);
        getPageLoggedinState();

        Mixpanel.track(EventNames.API_CALL, {
          "Crm Type": crmType,
          Status: "Success",
          "API Name": "SyncData Recieved",
          Section: "Extension",
          "Sync Data": tempSyncData
        });
      });
    }
    return () => {
      clearInterval(pageLoggedinStateIntervalRef.current);
    };
  }, [dataUpdate]);

  useEffect(() => {
    if (ctaState === EXTENSION_CTA_STATES_LIST.SYCNING) {
      startTriggerExtensionSyncFlow();
    }
  }, [ctaState, dataUpdate]);

  useEffect(() => {
    if (chromeVariableAvailable()) {
      chrome?.runtime?.onMessage?.removeListener(messageListener);
      chrome?.runtime?.onMessage?.addListener(messageListener);

      clearInterval(keepAlivePingPongIntervalRef.current);
      keepAlivePingPongIntervalRef.current = setInterval(() => {
        chrome?.runtime?.sendMessage({
          action: "ping"
        });
      }, 5000);
    }
    return () => {
      if (chromeVariableAvailable()) {
        chrome?.runtime?.onMessage?.removeListener(messageListener);
      }
      clearInterval(keepAlivePingPongIntervalRef.current);
    };
  }, []);

  const renderReportAnIssueCta = () => {
    return (
      <ButtonBase
        disableTouchRipple
        disableRipple
        sx={{
          color: ColorsConst.DARK_TERTIARY,
          bgcolor: ColorsConst.DARK_SURFACE_CONTAINER,
          justifyContent: "flex-start",
          position: "fixed",
          zIndex: 1,
          bottom: "0px",
          padding: "16px",
          width: "100%",
          display: "flex",
          alignItems: "center"
        }}
        onClick={() => {
          setShowReportIssueForm(true);
          Mixpanel.track(EventNames.BUTTON_CLICKED, {
            Section: "Intermediate Screen",
            "Button Name": "Report issue",
            "Button Type": "Primary",
            "Relevant Content": {
              "Crm Type": crmType
            }
          });
        }}
      >
        <InfoOutlinedIcon sx={{ color: "inherit" }} />
        &nbsp;
        <Typography
          fontSize={"16px"}
          fontWeight={500}
          lineHeight={"20px"}
          letterSpacing={"0.1px"}
          color={"inherit"}
        >
          Report issue
        </Typography>
      </ButtonBase>
    );
  };

  const handleBugSubmit = async () => {
    if (meetingIdRef.current && formDesc) {
      try {
        setReportIssueApiState("SENDING");
        await post(ROUTES.REPORT_BUG(meetingIdRef.current), {
          description: formDesc
        });
        setReportIssueApiState("COMPLETE");
        setFormDesc("");
      } catch (e) {
        console.error("handleBugSubmit", e);
      }
    }
  };

  const renderReportIssueForm = () => {
    return (
      <Popover
        onClose={() => {
          setShowReportIssueForm(false);
        }}
        open
        sx={{
          ".MuiBackdrop-root": {
            background: "rgba(0,0,0,0.60)"
          },
          ".MuiPaper-root": {
            bgcolor: ColorsConst.DARK_SURFACE_CONTAINER,
            overflowY: "auto",
            left: "50% !important",
            top: "50% !important",
            transform: "translate(-50%, -50%) !important",
            borderRadius: "16px",
            width: "100%"
          }
        }}
      >
        <Box
          p={"24px"}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={reportIssueApiState === "COMPLETE" ? "16px" : "24px"}
        >
          {reportIssueApiState === "COMPLETE" ? (
            <>
              <CheckCircleIcon
                sx={{
                  color: ColorsConst.DARK_TERTIARY,
                  height: "48px",
                  width: "48px"
                }}
              />
              <M3BodyMediumTypography>
                Thank you! We have got your feedback,
                <br />
                and will look into it as soon as possible.
              </M3BodyMediumTypography>
              <ButtonBase
                onClick={() => {
                  setShowReportIssueForm(false);
                }}
                sx={{
                  p: "10px 24px",
                  mt: "8px",
                  borderRadius: "100px",
                  border: `1px solid ${ColorsConst.DARK_OUTLINE}`
                }}
              >
                <M3LabelLargeTypography color={ColorsConst.DARK_PRIMARY}>
                  Close
                </M3LabelLargeTypography>
              </ButtonBase>
            </>
          ) : (
            <>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                width={"100%"}
              >
                <M3TitleLarge>Report issue</M3TitleLarge>
                <CloseButton
                  sxProps={{
                    position: "relative",
                    top: "unset",
                    right: "unset",
                    p: "0px"
                  }}
                  onClose={() => {
                    setShowReportIssueForm(false);
                  }}
                />
              </Box>
              <textarea
                onChange={(e) => {
                  setFormDesc(e.target.value);
                }}
                placeholder="Describe your issue here..."
                className={styles.bugDescription}
                value={formDesc}
              ></textarea>
              <ButtonBase
                onClick={handleBugSubmit}
                disabled={!formDesc}
                sx={{
                  p: "10px 24px",
                  borderRadius: "100px",
                  background:
                    !formDesc || reportIssueApiState === "SENDING"
                      ? ColorsConst.DARK_OUTLINE_VARIANT
                      : "#4E00C2",
                  color:
                    !formDesc || reportIssueApiState === "SENDING"
                      ? ColorsConst.SCHEMES_OUTLINE
                      : "#fff"
                }}
              >
                <M3LabelLargeTypography color={"inherit"}>
                  {reportIssueApiState === "SENDING"
                    ? "Sending"
                    : "Report issue"}
                </M3LabelLargeTypography>
              </ButtonBase>
            </>
          )}
        </Box>
      </Popover>
    );
  };

  const renderCrmExtensionPage = () => {
    switch (crmType) {
      case CRMS.ADVYZON:
        return (
          <>
            <AdvyzonExtension
              triggerResumeRetryFlow={() => {
                setCtaState(EXTENSION_CTA_STATES_LIST.SYCNING);
              }}
              ctaState={ctaState}
              extNotes={crmSyncData?.notes}
              extActionItems={crmSyncData?.actionItems}
              renderReportAnIssueCta={renderReportAnIssueCta}
            />
            {showReportIssueForm && renderReportIssueForm()}
          </>
        );
      default:
        return <></>;
    }
  };

  const syncDataBackToDB = async () => {
    try {
      if (meetingIdRef.current) {
        if (crmType === CRMS.ADVYZON) {
          await post(ROUTES.SET_ADVYZON_EXTENSION_DATA(meetingIdRef.current), {
            notes: crmSyncData.notes,
            actionItems: crmSyncData.actionItems
          });
        }
      }
    } catch (e) {}
  };

  useEffect(() => {
    syncDataBackToDB();
  }, [crmSyncData]);

  if (!crmType || !crmSyncData) {
    return <Loader />;
  }

  return <>{renderCrmExtensionPage()}</>;
};

export default ExtensionUI;
