import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ButtonBase,
  Divider,
  Drawer,
  Link,
  Typography,
} from "@mui/material";
import TodayIcon from "@mui/icons-material/Today";
import GroupIcon from "@mui/icons-material/Group";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import KeyboardAltOutlinedIcon from "@mui/icons-material/KeyboardAltOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import ColorsConst from "../../utils/ColorsConst";
import history from "../../router/history";
import { RootState } from "../../redux/store";

import "./sideNav.css";
import { setToken } from "../../redux/reducers/appSlice";
import { ROUTES } from "../../api/config";
import { get } from "../../api";
import Mixpanel, { EventNames } from "../../utils/analytics/mixpanel";
import { useDeviceStatus } from "../../hooks/useDeviceStatus";
import { PRIVACY_POLICY, TERMS_OF_USE } from "../../utils/Const";
import LogoutModal from "./LogoutModal";
import React, { useState } from "react";
import packageJson from "../../../package.json";
import ThirdPartyIntegrations from "../ThirdPartyIntegrations/ThirdPartyIntegrations";
import IntegrationIcon from "./../../assets/img/integrations.svg";
import { fetchIntegrationsList } from "../../redux/actions/appActions";
import BarChartIcon from "@mui/icons-material/BarChart";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import isEqual from "lodash/isEqual";

function generateRegex(patterns) {
  const regexPattern = `^/(${patterns?.join("|")})$`;
  return new RegExp(regexPattern);
}

// let IS_ALREADY_TAB_SELECTED_TAB_HACK = false;

const FIRST_ITEMS = [
  {
    // pathnames: ["all-meetings", "meeting-details/\\d+"],
    icon: TodayIcon,
    name: "My Meetings",
    id: "MY_MEETINGS",
    count: 0,
    showChildren: true,
    childrenItems: [
      {
        pathnames: ["all-meetings/past"],
        name: "Past",
        id: "PAST",
      },
      {
        pathnames: ["all-meetings/today"],
        name: "Today",
        id: "TODAY",
      },
      {
        pathnames: ["all-meetings/upcoming"],
        name: "Upcoming",
        id: "UPCOMING",
      },
    ],
  },
  {
    // pathnames: ["shared-meetings" , "shared/meeting-details/\\d+"],
    icon: GroupIcon,
    name: "Shared Meetings",
    id: "SHARED_MEETINGS",
    count: 0,
    showChildren: false,
    childrenItems: [
      {
        pathnames: ["shared-meetings/past"],
        name: "Past",
        id: "PAST",
      },
      {
        pathnames: ["shared-meetings/today"],
        name: "Today",
        id: "TODAY",
      },
      {
        pathnames: ["shared-meetings/upcoming"],
        name: "Upcoming",
        id: "UPCOMING",
      },
    ],
  },
  // {
  //   pathnames: ["past-meetings"],
  //   icon: TodayIcon,
  //   name: "Past meetings",
  //   count: 0,
  // },
  // {
  //   pathnames: [
  //     "all-clients",
  //     "client/\\d+",
  //     "client/\\d+/meeting-details/\\d+",
  //   ],
  //   icon: PersonOutlineOutlinedIcon,
  //   name: "Clients",
  //   count: 0,
  // },
];

const SECOND_ITEMS = [
  {
    pathnames: ["customize-notes"],
    icon: KeyboardAltOutlinedIcon,
    name: "Customize",
    count: 0,
  },
  {
    pathnames: ["settings"],
    icon: SettingsOutlinedIcon,
    name: "Settings",
    count: 0,
  },
];

const SideNavItem = (props) => {
  const meetingDetails = useSelector(
    (state: RootState) => state.app.meetingDetails.data,
  );

  const { isExtension } = useDeviceStatus();
  //   const location = useLocation();
  //   const regex = generateRegex(props.pathnames);
  //   const selected =  regex?.test(location.pathname);
  //   const textColor = selected
  //     ? ColorsConst.SURFACE_CONTAINER_LOW
  //     : ColorsConst.ON_SURFACE_VARIANT;

  // const _onClick = () => {
  // 	history.push(`/${props.pathnames[0]}`);
  // 	props.handleClose();
  //   };

  //   if (isExtension) {
  //     const extensionTextColor = selected
  //       ? ColorsConst.SURFACE_CONTAINER_LOW
  //       : ColorsConst.ON_SURFACE_VARIANT;
  //     return (
  //       <ButtonBase sx={{ display: "block", mb: "12px" }} onClick={_onClick}>
  //         <Box
  //           sx={{
  //             bgcolor: selected ? ColorsConst.DARK_PRIMARY : "transparent",
  //             borderRadius: 8,
  //             px: 2,
  //             display: "flex",
  //             alignItems: "center",
  //             py: "4px",
  //             mb: "4px",
  //           }}
  //         >
  //           {/* <Icon sx={{ color: textColor, height: 20 }} /> */}
  //         </Box>
  //         <Typography
  //           variant="caption"
  //           sx={{
  //             color: extensionTextColor,
  //             textAlign: "center",
  //           }}
  //         >
  //           {props.name}
  //         </Typography>
  //         {/* {!!props.count && (
  //           <Typography sx={{ color: textColor }}>{props.count}</Typography>
  //         )} */}
  //       </ButtonBase>
  //     );
  //   }

  const RenderNavItem = ({ children, ...navItemData }) => {
    const { icon: Icon } = navItemData;

    const [showChildren, setShowChildren] = useState(true);
    const location = useLocation();
    const regex = generateRegex(navItemData.pathnames);
    let selected = regex?.test(location.pathname);
    const isSharedMeeting = location.pathname.includes("/shared");

    const params = new URLSearchParams(window.location.search);
    const getSection = params.get("section");
    if (
      !selected &&
      getSection === navItemData.id &&
      // !IS_ALREADY_TAB_SELECTED_TAB_HACK &&
      ((isSharedMeeting && navItemData.pathnames[0].includes("shared")) ||
        (!isSharedMeeting && !navItemData.pathnames[0].includes("shared")))
    ) {
      selected = true;
    }

    // if (!selected && navItemData.pathnames && meetingDetails && !IS_ALREADY_TAB_SELECTED_TAB_HACK) {
    // 	const meetingTime = new Date(meetingDetails.start_time).getTime();

    // 	const currentTime = new Date();
    // 	currentTime.setHours(0, 0, 0, 0);

    // 	if (
    // 		meetingTime < currentTime.getTime() &&
    // 		navItemData.pathnames[0].includes("/past") &&
    // 		((isSharedMeeting && navItemData.pathnames[0].includes("shared")) ||
    // 			(!isSharedMeeting && !navItemData.pathnames[0].includes("shared")))
    // 	) {
    // 		selected = true;
    // 	} else if (
    // 		currentTime.getTime() <= meetingTime &&
    // 		meetingTime < currentTime.getTime() + 86400000 &&
    // 		navItemData.pathnames[0].includes("/today") &&
    // 		((isSharedMeeting && navItemData.pathnames[0].includes("shared")) ||
    // 			(!isSharedMeeting && !navItemData.pathnames[0].includes("shared")))
    // 	) {
    // 		selected = true;
    // 	} else if (
    // 		currentTime.getTime() + 86400000 < meetingTime &&
    // 		navItemData.pathnames[0].includes("/upcoming") &&
    // 		((isSharedMeeting && navItemData.pathnames[0].includes("shared")) ||
    // 			(!isSharedMeeting && !navItemData.pathnames[0].includes("shared")))
    // 	) {
    // 		selected = true;
    // 	}

    // 	if(selected){
    // 		IS_ALREADY_TAB_SELECTED_TAB_HACK = true;
    // 	}
    // }
    // if(selected){
    // 	IS_ALREADY_TAB_SELECTED_TAB_HACK = true;
    // }

    const handleNavClick = () => {
      if (navItemData.pathnames) {
        // IS_ALREADY_TAB_SELECTED_TAB_HACK = false;
        history.push(`/${navItemData.pathnames[0]}`);
        navItemData.setSelectedTab?.(navItemData.id);
        if (navItemData.isChild || !children) {
          navItemData.handleClose?.();
        }
      } else {
        if (navItemData.id === navItemData.selectedTab) {
          setShowChildren((prev) => !prev);
        } else {
          // IS_ALREADY_TAB_SELECTED_TAB_HACK = false;
          navItemData.setSelectedTab?.(navItemData.id);
          if (navItemData.isChild || !children) {
            navItemData.handleClose?.();
          }
        }
      }
    };

    const isParent = !navItemData.isChild;
    const isChild = navItemData.isChild;
    const isParentAndSelected =
      isParent &&
      navItemData.id === navItemData.selectedTab &&
      ((children && showChildren) || !children);

    const textColor = isChild
      ? selected
        ? ColorsConst.ON_SURFACE_VARIANT
        : ColorsConst.SCHEMES_OUTLINE
      : ColorsConst.ON_SURFACE_VARIANT;

    return (
      <>
        <ButtonBase
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            // my: 2,
            bgcolor: isParentAndSelected
              ? "rgba(207,189,255,0.12)"
              : "transparent",
            px: 2,
            py: 1.2,
            borderRadius: 8,
            width: "100%",
            // ...(!isParent && {my:"8px"})
          }}
          onClick={handleNavClick}
        >
          {Icon && <Icon sx={{ color: textColor }} />}
          <Typography
            variant={"subtitle2"}
            sx={{
              color: textColor,
              ml: !isParent ? "40px" : 2,
              mr: 2,
              flex: 1,
              textAlign: "left",
            }}
          >
            {navItemData.name}
          </Typography>
          {!!navItemData.count && (
            <Typography sx={{ color: textColor }}>
              {navItemData.count}
            </Typography>
          )}
          {isParent && children && (
            <KeyboardArrowDownIcon
              sx={{
                color: textColor,
                transform: `rotate(${isParentAndSelected ? 180 : 0}deg)`,
              }}
            />
          )}
        </ButtonBase>
        {isParentAndSelected && <>{children}</>}
      </>
    );
  };

  const renderParentNavItem = () => {
    return (
      <>
        <RenderNavItem {...props}>
          {props.childrenItems.map((childNavData) => {
            return (
              <RenderNavItem
                key={childNavData.name}
                {...childNavData}
                handleClose={props.handleClose}
                isChild
              />
            );
          })}
        </RenderNavItem>
      </>
    );
  };

  return (
    <>
      {props.childrenItems ? (
        renderParentNavItem()
      ) : (
        <RenderNavItem {...props} />
      )}
    </>
  );
};

const SideNav = React.memo((props) => {
  const [selectedTab, setSelectedTab] = useState("MY_MEETINGS");
  const { isExtension, isMobile } = useDeviceStatus();
  const userDetails = useSelector((state: RootState) => state.app.userDetails);
  const dispatch = useDispatch();
  const [isLogoutModal, setIsLogoutModal] = useState(false);

  const [isIntegrationModal, setIsIntegrationModal] = useState(false);

  const firstName = userDetails?.name?.split(" ")?.[0];

  const handleClose = () => {
    props.setIsDrawerOpen(false);
  };

  const onLogout = async () => {
    dispatch(setToken(null));
    Mixpanel.track(EventNames.USER_LOGOUT, {
      "Log Out Method": "Manual Logout",
    });
    Mixpanel.reset();
    try {
      const res = await get(ROUTES.LOGOUT);
      //   localStorage.removeItem("calendar-close-count");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Drawer
      variant={!isMobile || isExtension ? "permanent" : "temporary"}
      // For mobile mode, add the following properties:
      open={props.isDrawerOpen} // isOpen is a state variable that controls the drawer's visibility
      onClose={handleClose} // handleClose is a function to close the drawer
      ModalProps={{ keepMounted: true }} // To improve performance on mobile
      // For desktop mode, remove the open and onClose properties
      className={isExtension ? "chrome-extension" : ""}
      sx={{
        ".MuiBackdrop-root": {
          opacity: `${0.65} !important`,
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: ColorsConst.DARK_SURFACE_CONTAINER,
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          flex={1}
          sx={{
            marginTop: "70px",
            "@media (min-width: 820px)": {
              marginTop: "64px",
            },
          }}
        >
          {FIRST_ITEMS.map((item) => (
            <SideNavItem
              {...item}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              key={item.name}
              handleClose={handleClose}
            />
          ))}
          {userDetails?.is_compliance_dashboard_enabled && (
            <SideNavItem
              id="DASHBOARD"
              pathnames={["dashboard"]}
              selectedTab={selectedTab}
              icon={BarChartIcon}
              name="Dashboard"
              count={0}
              handleClose={handleClose}
              setSelectedTab={setSelectedTab}
            />
          )}
          {/* {!isExtension && (
            <Divider
              sx={{ backgroundColor: ColorsConst.DARK_OUTLINE_VARIANT }}
            />
          )}
          {SECOND_ITEMS.map((item) => (
            <SideNavItem {...item} key={item.name} handleClose={handleClose} />
          ))} */}
        </Box>
        <Box>
          {!isExtension && (
            <Divider
              sx={{
                backgroundColor: ColorsConst.DARK_OUTLINE_VARIANT,
                mb: "16px",
              }}
            />
          )}
          <Accordion
            sx={{
              bgcolor: ColorsConst.DARK_SURFACE_CONTAINER,
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              sx={{
                margin: "0px !important",
                py: "12px",
                px: "0px",
                alignItems: "center",
                minHeight: "unset !important",
                ".MuiAccordionSummary-content": {
                  m: "0px !important",
                  alignItems: "center",
                },
              }}
              expandIcon={
                <KeyboardArrowDownIcon sx={{ color: ColorsConst.ON_SURFACE }} />
              }
            >
              <AccountCircleIcon
                sx={{ color: ColorsConst.ON_SURFACE_VARIANT }}
              />
              <Typography
                variant={isExtension ? "caption" : "subtitle2"}
                sx={{
                  color: ColorsConst.ON_SURFACE_VARIANT,
                  ml: isExtension ? 0 : "12px",
                  textAlign: "center",
                }}
              >
                {firstName}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: "0px" }}>
              {userDetails?.is_integration_enabled && (
                <ButtonBase
                  sx={{
                    width: "100%",
                    justifyContent: isExtension ? "center" : "flex-start",
                    alignItems: "center",
                    display: "flex",
                    pl: "32px",
                  }}
                  onClick={() => setIsIntegrationModal(true)}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: isExtension ? "column" : "row",
                      pb: 1.5,
                      pt: 1.5,
                    }}
                  >
                    {/* <LogoutIcon sx={{ color: ColorsConst.ON_SURFACE_VARIANT }} /> */}
                    <img src={IntegrationIcon} />
                    <Typography
                      variant={isExtension ? "caption" : "subtitle2"}
                      sx={{
                        color: ColorsConst.ON_SURFACE_VARIANT,
                        ml: isExtension ? 0 : "12px",
                        textAlign: "center",
                      }}
                    >
                      Integrations
                    </Typography>
                  </Box>
                </ButtonBase>
              )}
              <ButtonBase
                sx={{
                  width: "100%",
                  justifyContent: isExtension ? "center" : "flex-start",
                  alignItems: "center",
                  display: "flex",
                  pl: "32px",
                }}
                onClick={() => setIsLogoutModal(true)}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: isExtension ? "column" : "row",
                    pb: 1.5,
                    pt: 1.5,
                  }}
                >
                  <LogoutIcon sx={{ color: ColorsConst.ON_SURFACE_VARIANT }} />
                  <Typography
                    variant={isExtension ? "caption" : "subtitle2"}
                    sx={{
                      color: ColorsConst.ON_SURFACE_VARIANT,
                      ml: isExtension ? 0 : "12px",
                      textAlign: "center",
                    }}
                  >
                    Logout
                  </Typography>
                </Box>
              </ButtonBase>
            </AccordionDetails>
          </Accordion>
          <Typography
            variant="body2"
            sx={{
              color: "#7A7488",
              mb: 2,
              mt: 1.5,
              textAlign: isExtension ? "center" : "inherit",
            }}
          >
            Read our{" "}
            <Link
              sx={{
                color: "#7A7488",
                cursor: "pointer",
                textDecorationColor: "#7A7488",
              }}
              href={TERMS_OF_USE}
              underline="always"
              target="_blank"
              rel="noopener"
            >
              Terms of Use
            </Link>{" "}
            and{" "}
            <Link
              sx={{
                color: "#7A7488",
                cursor: "pointer",
                textDecorationColor: "#7A7488",
              }}
              href={PRIVACY_POLICY}
              underline="always"
              target="_blank"
              rel="noopener"
            >
              Privacy Policy.
            </Link>
          </Typography>
          <Typography
            variant="caption"
            sx={{
              color: "#7A7488",
              fontSize: "10px",
              position: "absolute",
              left: "30px",
              bottom: "8px",
              ml: isExtension ? "-6px" : -2,
            }}
          >
            v{packageJson.version}
          </Typography>
        </Box>
      </Box>
      <LogoutModal
        open={isLogoutModal}
        onSecondaryClick={() => setIsLogoutModal(false)}
        onPrimaryClick={onLogout}
      />
      {isIntegrationModal && (
        <ThirdPartyIntegrations
          section="Side Nav"
          onClose={() => {
            dispatch(fetchIntegrationsList());
            setIsIntegrationModal(false);
          }}
        />
      )}
    </Drawer>
  );
});

export default SideNav;
