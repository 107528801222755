import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  fetchUserProfile,
  fetchClientDetails,
  fetchClientList,
  fetchTeamMemberList,
  fetchMeetingList,
  fetchClientMeetingList,
  fetchMeetingDetails,
  updateMeetingDetails,
  createMeeting,
  fetchContactList,
  fetchMeetingTypeList,
  fetchAdvisorsList,
  fetchEMoneyDetails,
  fetchMeetingListV2,
  fetchMeetingListV3,
  fetchMeetingSharedList,
  fetchMeetingShareableList,
  updateMeetingShareList,
  fetchSharedMeetingList,
  addNoteToSalesForce,
  addActionItemsToSalesForce,
  fetchOrganizationDetails,
  finalizeMeetingNotes,
  fetchIntegrationsList
} from "../actions/appActions";
import { toastEmitter } from "../../components/toast/toastManager";
import history from "../../router/history";
import Mixpanel, { EventNames } from "../../utils/analytics/mixpanel";
import { isAndroidApp, isIOSApp, sendToNativeApps } from "../../utils/Util";

interface AppState {
  token: string | null;
  clientList: any;
  teamMemberList: any;
  clientDetails: any;
  userDetails: any;
  organizationDetails: any;
  isUserDetailsLoading: boolean;
  meetingList: any;
  clientMeetingList: any;
  meetingDetails: any;
  socketOnMsgCount: number;
  addMeetingToggle: boolean;
  contacts: any;
  advisors: any;
  meetingTypes: any;
  eMoneyDetails: any;
  meetingListV2: any;
  meetingListV3: any;
  sharedMeetingList: any;
  meetingShareableList: any;
  updateMeetingShared: any;
  meetingSharedList: any;
  salesForceNotes: any;
  salesForceActionItems: any;
  isDownloading: any;
  isPublishing: boolean;
  integrationsList: any;
  consentEmail: any;
}

const initialState: AppState = {
  token: null,
  userDetails: null,
  isUserDetailsLoading: false,
  organizationDetails: {
    isLoading: false,
    data: null
  },
  clientList: {
    data: [],
    isLoading: false,
    error: null
  },
  teamMemberList: {
    data: [],
    isLoading: false,
    error: null
  },
  clientDetails: {
    data: null,
    isLoading: false,
    error: null
  },
  meetingList: {
    upcoming: [],
    current: [],
    isLoading: true,
    paginationLoading: false,
    error: null
  },
  sharedMeetingList: {
    list: [],
    isLoading: true,
    paginationLoading: false,
    error: null
  },
  meetingListV3: {
    list: [],
    isLoading: true,
    paginationLoading: false,
    error: null,
    SHARED: {
      sectionType: "",
      filterParams: {
        searchTerm: "",
        date: null,
        clientIds: []
      }
    },
    MY: {
      sectionType: "",
      filterParams: {
        searchTerm: "",
        date: null,
        clientIds: [],
        teamMemberIds: []
      }
    }
  },
  meetingListV2: {
    past: [],
    upcoming: [],
    todayPast: [],
    todayUpcoming: [],
    isLoading: true,
    paginationLoading: false,
    error: null,
    next_cursor: ""
  },
  clientMeetingList: {
    data: [],
    isLoading: false,
    error: null
  },
  meetingDetails: {
    data: null,
    isLoading: false,
    error: null
  },
  eMoneyDetails: {
    data: null,
    isLoading: false,
    error: null
  },
  meetingShareableList: {
    data: null,
    isLoading: false,
    error: null
  },
  updateMeetingShared: {
    data: null,
    isLoading: false,
    error: null
  },
  meetingSharedList: {
    data: null,
    isLoading: false,
    error: null
  },
  socketOnMsgCount: 0,
  addMeetingToggle: false,
  contacts: [],
  advisors: [],
  meetingTypes: [],
  salesForceNotes: {
    isLoading: false,
    error: null,
    data: null
  },
  salesForceActionItems: {
    isLoading: false,
    error: null,
    data: null
  },
  isDownloading: null,
  isPublishing: false,
  integrationsList: {
    isLoading: false,
    error: null,
    list: null
  },
  consentEmail: {
    clientList: [],
    show: false,
    meetingId: "",
    section: ""
  }
};

function addUserProfileCases(builder) {
  builder
    .addCase(fetchUserProfile.pending, (state) => {
      state.isUserDetailsLoading = true;
    })
    .addCase(fetchUserProfile.fulfilled, (state, action) => {
      state.isUserDetailsLoading = false;
      state.userDetails = action.payload;

      Mixpanel.identify(state.userDetails.user_id);

      const authToken = localStorage.getItem("token");

      if (window.isIOS || window.isMacApp || window.AndroidInterface) {
        sendToNativeApps(
          "initializeUser",
          JSON.stringify({
            userId: state.userDetails.user_id,
            userEmail: state.userDetails.email,
            organizationId: state.userDetails.organization_id,
            organizationName: state.userDetails.firm,
            mixPanelToken: process.env.REACT_APP_MIXPANEL_TOKEN,
            authToken: state.app?.token || authToken
          })
        );
      }

      if (state.userDetails?.onboarded) {
        localStorage.setItem("user_onboard", "true");
      } else {
        localStorage.removeItem("user_onboard");
      }
    })
    .addCase(fetchUserProfile.rejected, (state, action) => {
      state.isUserDetailsLoading = false;
    });
}

function addClientListCases(builder) {
  builder
    .addCase(fetchClientList.pending, (state) => {
      state.clientList.isLoading = true;
      state.clientList.error = null;
    })
    .addCase(fetchClientList.fulfilled, (state, action: any) => {
      state.clientList.isLoading = false;
      state.clientList.error = null;
      state.clientList.data = action.payload.clients;
    })
    .addCase(fetchClientList.rejected, (state, action: any) => {
      state.clientList.isLoading = false;
      state.clientList.error =
        action.error.message || "Error occurred while fetching client list.";
    });
}

function addTeamMemberListCases(builder) {
  builder
    .addCase(fetchTeamMemberList.pending, (state) => {
      state.teamMemberList.isLoading = true;
      state.teamMemberList.error = null;
    })
    .addCase(fetchTeamMemberList.fulfilled, (state, action: any) => {
      state.teamMemberList.isLoading = false;
      state.teamMemberList.error = null;
      state.teamMemberList.data = action.payload.advisors;
    })
    .addCase(fetchTeamMemberList.rejected, (state, action: any) => {
      state.teamMemberList.isLoading = false;
      state.teamMemberList.error =
        action.error.message || "Error occurred while fetching client list.";
    });
}

function addClientDetailsCases(builder) {
  builder
    .addCase(fetchClientDetails.pending, (state) => {
      state.clientDetails.isLoading = true;
      state.clientDetails.error = null;
    })
    .addCase(fetchClientDetails.fulfilled, (state, action) => {
      state.clientDetails.isLoading = false;
      state.clientDetails.data = action.payload;
    })
    .addCase(fetchClientDetails.rejected, (state, action) => {
      state.clientDetails.isLoading = false;
      state.clientDetails.error = action.error.message;
    });
}

function addMeetingListCases(builder) {
  builder
    .addCase(fetchMeetingList.pending, (state, action) => {
      if (action.meta.arg === 0) {
        state.meetingList.isLoading = true;
      } else {
        state.meetingList.paginationLoading = true;
      }
      state.meetingList.error = null;
    })
    .addCase(fetchMeetingList.fulfilled, (state, action) => {
      state.meetingList.paginationLoading = false;
      state.meetingList.isLoading = false;
      if (action.meta.arg === 0) {
        state.meetingList.upcoming = action.payload.data;
      } else {
        state.meetingList.upcoming = [
          ...state.meetingList.upcoming,
          ...action.payload.data
        ];
      }
    })
    .addCase(fetchMeetingList.rejected, (state, action) => {
      state.meetingList.paginationLoading = false;
      state.meetingList.isLoading = false;
      state.meetingList.error = action.error.message;
    });
}

function addIntegrationsListCases(builder) {
  builder
    .addCase(fetchIntegrationsList.pending, (state, action) => {
      state.integrationsList.isLoading = true;
      state.integrationsList.error = null;
    })
    .addCase(fetchIntegrationsList.fulfilled, (state, action) => {
      state.integrationsList.isLoading = false;
      state.integrationsList.list = [
        // ...(state.integrationsList.list || []),
        ...action.payload.data.sections
      ];
    })
    .addCase(fetchIntegrationsList.rejected, (state, action) => {
      state.sharedMeetingList.isLoading = false;
      state.sharedMeetingList.error = action.error.message;
    });
}

function addSharedMeetingListCases(builder) {
  builder
    .addCase(fetchSharedMeetingList.pending, (state, action) => {
      if (action.meta?.arg?.offset >= 0 && action.meta.arg.offset === 0) {
        state.sharedMeetingList.isLoading = true;
        state.sharedMeetingList.paginationLoading = false;
      } else if (action.meta?.arg?.offset >= 0 && action.meta.arg.offset > 0) {
        state.sharedMeetingList.isLoading = false;
        state.sharedMeetingList.paginationLoading = true;
      }
      state.sharedMeetingList.error = null;
    })
    .addCase(fetchSharedMeetingList.fulfilled, (state, action) => {
      if (action.payload.data.reset) {
        state.sharedMeetingList.list = [];
        return;
      }

      state.sharedMeetingList.paginationLoading = false;
      state.sharedMeetingList.isLoading = false;
      if (action.meta?.arg?.offset >= 0 && action.meta.arg.offset === 0) {
        state.sharedMeetingList.list = action.payload.data.meetings;
      } else {
        state.sharedMeetingList.list = [
          ...state.sharedMeetingList.list,
          ...action.payload.data.meetings
        ];
      }
    })
    .addCase(fetchSharedMeetingList.rejected, (state, action) => {
      state.sharedMeetingList.paginationLoading = false;
      state.sharedMeetingList.isLoading = false;
      state.sharedMeetingList.error = action.error.message;
    });
}

function addMeetingListV3Cases(builder) {
  builder
    .addCase(fetchMeetingListV3.pending, (state, action) => {
      if (action.meta?.arg?.offset >= 0 && action.meta.arg.offset === 0) {
        state.meetingListV3.isLoading = true;
        state.meetingListV3.paginationLoading = false;
      } else if (action.meta?.arg?.offset >= 0 && action.meta.arg.offset > 0) {
        state.meetingListV3.isLoading = false;
        state.meetingListV3.paginationLoading = true;
      }
      state.meetingListV3.error = null;
    })
    .addCase(fetchMeetingListV3.fulfilled, (state, action) => {
      if (action.payload.data.reset) {
        state.meetingListV3.list = [];
        return;
      }

      if (action.payload.data.sectionTypeUpdate) {
        state.meetingListV3[action.payload.data.type].sectionType =
          action.payload.data.sectionType;
        return;
      }

      if (action.payload.data.filtersParamsUpdate) {
        state.meetingListV3[action.payload.data.type].filterParams = {
          ...action.payload.data
        };
        return;
      }

      if (action.payload.data.delete) {
        state.meetingListV3.list = (
          action.payload.data.meetingList || state.meetingListV3.list
        ).filter((d) => d.id != action.payload.data.meetingId);
        return;
      }

      if (action.payload.data.update) {
        state.meetingListV3.list = (
          action.payload.data.meetingList || state.meetingListV3.list
        ).map((d) => {
          if (d.id == action.payload.data.meetingId) {
            return {
              ...d,
              ...action.payload.data.meetingData,
              id: action.payload.data.meetingId
            };
          } else {
            return d;
          }
        });
        return;
      }

      state.meetingListV3.paginationLoading = false;
      state.meetingListV3.isLoading = false;
      if (action.meta?.arg?.offset >= 0 && action.meta.arg.offset === 0) {
        state.meetingListV3.list = action.payload.data.meetings;
      } else {
        state.meetingListV3.list = [
          ...state.meetingListV3.list,
          ...action.payload.data.meetings
        ];
      }
    })
    .addCase(fetchMeetingListV3.rejected, (state, action) => {
      state.meetingListV3.paginationLoading = false;
      state.meetingListV3.isLoading = false;
      state.meetingListV3.error = action.error.message;
    });
}

function addMeetingListV2Cases(builder) {
  builder
    .addCase(fetchMeetingListV2.pending, (state, action) => {
      if (action.meta?.arg?.offset >= 0 && action.meta.arg.offset === 0) {
        state.meetingListV2.isLoading = true;
        state.meetingListV2.paginationLoading = false;
      } else if (action.meta?.arg?.offset >= 0 && action.meta.arg.offset > 0) {
        state.meetingListV2.isLoading = false;
        state.meetingListV2.paginationLoading = true;
      }
      state.meetingListV2.error = null;
    })
    .addCase(fetchMeetingListV2.fulfilled, (state, action) => {
      if (action.payload.data.reset) {
        state.meetingListV2.upcoming = [];
        state.meetingListV2.past = [];
        state.meetingListV2.todayPast = [];
        state.meetingListV2.todayUpcoming = [];
        state.meetingListV2.next_cursor = "";
        return;
      }

      state.meetingListV2.paginationLoading = false;
      state.meetingListV2.isLoading = false;
      state.meetingListV2.next_cursor = action.payload.data.next_cursor;
      const currentTime = Date.now();
      const currentDay = new Date();
      currentDay.setHours(0);
      currentDay.setMinutes(0);
      currentDay.setSeconds(0);
      currentDay.setMilliseconds(0);
      const pastList = [];
      const todayPastList = [];
      const todayUpcomingList = [];
      const upcomingList = [];

      action.payload.data.meetings.forEach((d) => {
        const meetingStartTime = new Date(d.start_time).getTime();
        const meetingEndTime = new Date(d.end_time).getTime();
        if (
          meetingStartTime >= currentDay.getTime() &&
          meetingEndTime < currentTime
        ) {
          if (!state.meetingListV2.todayPast.find((k) => d.id === k.id)) {
            todayPastList.push(d);
          }
        } else if (meetingEndTime < currentTime) {
          if (!state.meetingListV2.past.find((k) => d.id === k.id)) {
            pastList.push(d);
          }
        } else if (
          meetingStartTime < currentDay.getTime() + 86400000 &&
          meetingEndTime >= currentTime
        ) {
          if (!state.meetingListV2.todayUpcoming.find((k) => d.id === k.id)) {
            todayUpcomingList.push(d);
          }
        } else if (meetingStartTime > currentDay.getTime() + 86400000) {
          if (!state.meetingListV2.upcoming.find((k) => d.id === k.id)) {
            upcomingList.push(d);
          }
        }
      });

      // if (action.meta.arg === 0) {
      // pastList.sort((a, b) => new Date(a.start_time).getTime() - new Date(b.start_time).getTime());
      // todayUpcomingList.sort((a, b) => new Date(a.start_time).getTime() - new Date(b.start_time).getTime());
      // upcomingList.sort((a, b) => new Date(a.start_time).getTime() - new Date(b.start_time).getTime());
      // state.meetingListV2.upcoming = upcomingList;
      // state.meetingListV2.past = pastList;
      // state.meetingListV2.today = todayUpcomingList;
      // } else {
      const upcomingListSorted = [
        ...state.meetingListV2.upcoming,
        ...upcomingList
      ];
      upcomingListSorted.sort(
        (a, b) =>
          new Date(a.start_time).getTime() - new Date(b.start_time).getTime()
      );
      state.meetingListV2.upcoming = [...upcomingListSorted];

      const todayPastSorted = [
        ...state.meetingListV2.todayPast,
        ...todayPastList
      ];
      todayPastSorted.sort(
        (a, b) =>
          new Date(a.start_time).getTime() - new Date(b.start_time).getTime()
      );
      state.meetingListV2.todayPast = [...todayPastSorted];
      const todayUpcomingSorted = [
        ...state.meetingListV2.todayUpcoming,
        ...todayUpcomingList
      ];
      todayUpcomingSorted.sort(
        (a, b) =>
          new Date(a.start_time).getTime() - new Date(b.start_time).getTime()
      );
      state.meetingListV2.todayUpcoming = [...todayUpcomingSorted];
      const pastSorted = [...state.meetingListV2.past, ...pastList];
      pastSorted.sort(
        (a, b) =>
          new Date(b.start_time).getTime() - new Date(a.start_time).getTime()
      );
      state.meetingListV2.past = [...pastSorted];
      // }
    })
    .addCase(fetchMeetingListV2.rejected, (state, action) => {
      state.meetingListV2.paginationLoading = false;
      state.meetingListV2.isLoading = false;
      state.meetingListV2.error = action.error.message;
    });
}

function addClientMeetingListCases(builder) {
  builder
    .addCase(fetchClientMeetingList.pending, (state) => {
      state.clientMeetingList.isLoading = true;
      state.clientMeetingList.error = null;
      state.clientMeetingList.data = [];
    })
    .addCase(fetchClientMeetingList.fulfilled, (state, action) => {
      state.clientMeetingList.isLoading = false;
      state.clientMeetingList.data = action.payload;
    })
    .addCase(fetchClientMeetingList.rejected, (state, action) => {
      state.clientMeetingList.isLoading = false;
      state.clientMeetingList.error = action.error.message;
    });
}

function addMeetingDetailsCases(builder) {
  builder
    .addCase(fetchMeetingDetails.pending, (state, action) => {
      if (!action.meta.arg.silentRefresh) {
        state.meetingDetails.isLoading = true;
      }
      state.meetingDetails.error = null;
      // state.meetingDetails.data = null;
    })
    .addCase(fetchMeetingDetails.fulfilled, (state, action) => {
      state.meetingDetails.isLoading = false;
      state.meetingDetails.data = action.payload;
      if (action.meta.arg.silentRefresh) {
        state.socketOnMsgCount = state.socketOnMsgCount + 1;
      }
    })
    .addCase(fetchMeetingDetails.rejected, (state, action) => {
      state.meetingDetails.isLoading = false;
      state.meetingDetails.error = action.error.message;
    });
}

function addUpdateMeetingDetailsCases(builder) {
  builder
    .addCase(updateMeetingDetails.pending, (state) => {})
    .addCase(updateMeetingDetails.fulfilled, (state, action) => {
      toastEmitter.show({
        message: "Meeting updated successfully",
        severity: "success"
      });
      Mixpanel.trackDuringReduxChange(
        EventNames.API_CALL,
        {
          "API Name": "Edit Meeting",
          Screen: window.location.pathname,
          Section: action.meta.arg.section,
          Status: "Success"
        },
        {
          userDetails: state.userDetails
        }
      );
    })
    .addCase(updateMeetingDetails.rejected, (state, action) => {
      toastEmitter.show({ message: "Something went wrong", severity: "error" });
      Mixpanel.trackDuringReduxChange(
        EventNames.API_CALL,
        {
          "API Name": "Edit Meeting",
          Screen: window.location.pathname,
          Section: action.meta.arg.section,
          Status: "Failure",
          "API Failure Reason": action.error.message
        },
        {
          userDetails: state.userDetails
        }
      );
    });
}

function addCreateMeetingCases(builder) {
  builder
    .addCase(createMeeting.pending, (state) => {})
    .addCase(createMeeting.fulfilled, (state, action) => {
      toastEmitter.show({
        message: "Meeting created successfully",
        severity: "success"
      });
      const data = action.payload;
      if (data) {
        history.push(`/meeting-details/${data.id}`);
      }
    })
    .addCase(createMeeting.rejected, (state, action) => {
      toastEmitter.show({ message: "Something went wrong", severity: "error" });
    });
}
function addContactListCases(builder) {
  builder
    .addCase(fetchContactList.pending, (state) => {})
    .addCase(fetchContactList.fulfilled, (state, action) => {
      state.contacts = action.payload.clients.reduce((acc, contact) => {
        if (
          !acc.some((existingContact) => existingContact.name === contact.name)
        ) {
          acc.push(contact);
        }
        return acc;
      }, []);
    })
    .addCase(fetchContactList.rejected, (state, action) => {
      toastEmitter.show({ message: "Something went wrong", severity: "error" });
    });
}
function addAdvisorsListCases(builder) {
  builder
    .addCase(fetchAdvisorsList.pending, (state) => {})
    .addCase(fetchAdvisorsList.fulfilled, (state, action) => {
      state.advisors = action.payload.advisors.reduce((acc, contact) => {
        if (
          !acc.some((existingContact) => existingContact.name === contact.name)
        ) {
          acc.push(contact);
        }
        return acc;
      }, []);
    })
    .addCase(fetchAdvisorsList.rejected, (state, action) => {
      toastEmitter.show({ message: "Something went wrong", severity: "error" });
    });
}
function addMeetingTypeListCases(builder) {
  builder
    .addCase(fetchMeetingTypeList.pending, (state) => {})
    .addCase(fetchMeetingTypeList.fulfilled, (state, action) => {
      state.meetingTypes = action.payload.meeting_types;
    })
    .addCase(fetchMeetingTypeList.rejected, (state, action) => {
      toastEmitter.show({ message: "Something went wrong", severity: "error" });
    });
}

function addEMoneyDetailsCases(builder) {
  builder
    .addCase(fetchEMoneyDetails.pending, (state) => {
      state.eMoneyDetails.isLoading = true;
    })
    .addCase(fetchEMoneyDetails.fulfilled, (state, action) => {
      state.eMoneyDetails.data = action.payload;
    })
    .addCase(fetchEMoneyDetails.rejected, (state, action) => {
      state.eMoneyDetails.isLoading = false;
      toastEmitter.show({
        message: action.error.message || "Something went wrong",
        severity: "error"
      });
    });
}

function fetchMeetingSharedCases(builder) {
  builder
    .addCase(fetchMeetingSharedList.pending, (state) => {
      state.meetingSharedList.isLoading = true;
    })
    .addCase(fetchMeetingSharedList.fulfilled, (state, action) => {
      state.meetingSharedList.data = action.payload;
      state.meetingSharedList.isLoading = false;
    })
    .addCase(fetchMeetingSharedList.rejected, (state, action) => {
      state.meetingSharedList.isLoading = false;
      toastEmitter.show({
        message: action.error.message || "Something went wrong",
        severity: "error"
      });
    });
}

function fetchMeetingShareableListCases(builder) {
  builder
    .addCase(fetchMeetingShareableList.pending, (state) => {
      state.meetingShareableList.isLoading = true;
    })
    .addCase(fetchMeetingShareableList.fulfilled, (state, action) => {
      state.meetingShareableList.data = action.payload;
    })
    .addCase(fetchMeetingShareableList.rejected, (state, action) => {
      state.meetingShareableList.isLoading = false;
      toastEmitter.show({
        message: action.error.message || "Something went wrong",
        severity: "error"
      });
    });
}

function updateMeetingSharedCases(builder) {
  builder
    .addCase(updateMeetingShareList.pending, (state) => {
      state.updateMeetingShared.isLoading = true;
    })
    .addCase(updateMeetingShareList.fulfilled, (state, action) => {
      state.updateMeetingShared.data = action.payload;
      state.updateMeetingShared.isLoading = false;
      toastEmitter.show({
        message: "Shared Successfully",
        severity: "success"
      });
    })
    .addCase(updateMeetingShareList.rejected, (state, action) => {
      state.updateMeetingShared.isLoading = false;
      toastEmitter.show({
        message: action.error.message || "Something went wrong",
        severity: "error"
      });
    });
}

function addNoteToSalesForceCases(builder) {
  builder
    .addCase(addNoteToSalesForce.pending, (state) => {
      state.salesForceNotes.isLoading = true;
    })
    .addCase(addNoteToSalesForce.fulfilled, (state, action) => {
      state.salesForceNotes.isLoading = false;
      state.salesForceNotes.data = action.payload;
      toastEmitter.show({
        message: "Summary added to Salesforce XLR8 successfully",
        severity: "success"
      });
    })
    .addCase(addNoteToSalesForce.rejected, (state, action) => {
      state.salesForceNotes.isLoading = false;
      state.salesForceNotes.error = action.error.message;
      toastEmitter.show({
        message: action.error.message || "Something went wrong",
        severity: "error"
      });
    });
}

function addActionItemsToSalesForceCases(builder) {
  builder
    .addCase(addActionItemsToSalesForce.pending, (state) => {
      state.salesForceActionItems.isLoading = true;
      state.salesForceActionItems.data = null;
      state.salesForceActionItems.error = null;
    })
    .addCase(addActionItemsToSalesForce.fulfilled, (state, action) => {
      state.salesForceActionItems.isLoading = false;
      state.salesForceActionItems.data = action.payload;
      state.salesForceActionItems.error = null;
      toastEmitter.show({
        message: "Action items added to Salesforce XLR8 successfully",
        severity: "success"
      });
    })
    .addCase(addActionItemsToSalesForce.rejected, (state, action) => {
      state.salesForceActionItems.isLoading = false;
      state.salesForceActionItems.error = action.error.message;
      state.salesForceActionItems.data = null;
      toastEmitter.show({
        message: action.error.message || "Something went wrong",
        severity: "error"
      });
    });
}

function addOrganizationDetailsCases(builder) {
  builder
    .addCase(fetchOrganizationDetails.pending, (state) => {
      state.organizationDetails.isLoading = true;
    })
    .addCase(fetchOrganizationDetails.fulfilled, (state, action) => {
      state.organizationDetails.data = action.payload;
      state.organizationDetails.isLoading = false;
    })
    .addCase(fetchOrganizationDetails.rejected, (state, action) => {
      state.organizationDetails.isLoading = false;
    });
}

function finalizeMeetingNotesCases(builder) {
  builder
    .addCase(finalizeMeetingNotes.pending, (state) => {
      state.isPublishing = true;
    })
    .addCase(finalizeMeetingNotes.fulfilled, (state, action) => {
      state.isPublishing = false;
    })
    .addCase(finalizeMeetingNotes.rejected, (state, action) => {
      state.isPublishing = false;
      toastEmitter.show({ message: "Something went wrong", severity: "error" });
    });
}

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
      if (action.payload) {
        localStorage.setItem("token", action.payload); // Save token to localStorage
      } else {
        state.userDetails = null;
        localStorage.clear();
      }
    },
    setConsentEmailDetails(state, action) {
      state.consentEmail = action.payload;
    },
    incrementSocketOnMsgCount(state) {
      state.socketOnMsgCount = state.socketOnMsgCount + 1;
    },
    startDownloading(state, action) {
      state.isDownloading = action.payload;
    },
    endDownloading(state) {
      state.isDownloading = null;
    },
    setMeetingDetails(state, action) {
      state.meetingDetails.data = {
        ...state.meetingDetails.data,
        ...action.payload
      };
    },
    clearMeetingDetails(state) {
      state.meetingDetails = initialState.meetingDetails;
    },
    setAddMeetingToggle(state, action) {
      state.addMeetingToggle = action.payload;
    },
    clearEMoneyDetails(state) {
      state.eMoneyDetails = initialState.eMoneyDetails;
    },
    clearNoteToSalesForce(state) {
      state.salesForceNotes = initialState.salesForceNotes;
    },
    clearActionItemsToSalesForce(state) {
      state.salesForceActionItems = initialState.salesForceActionItems;
    }
  },
  extraReducers: (builder) => {
    addUserProfileCases(builder);
    addOrganizationDetailsCases(builder);
    addClientListCases(builder);
    addTeamMemberListCases(builder);
    addClientDetailsCases(builder);
    addMeetingListCases(builder);
    addMeetingListV2Cases(builder);
    addMeetingListV3Cases(builder);
    addSharedMeetingListCases(builder);
    addClientMeetingListCases(builder);
    addMeetingDetailsCases(builder);
    addUpdateMeetingDetailsCases(builder);
    addCreateMeetingCases(builder);
    addContactListCases(builder);
    addAdvisorsListCases(builder);
    addMeetingTypeListCases(builder);
    addEMoneyDetailsCases(builder);
    fetchMeetingSharedCases(builder);
    fetchMeetingShareableListCases(builder);
    updateMeetingSharedCases(builder);
    addNoteToSalesForceCases(builder);
    addActionItemsToSalesForceCases(builder);
    finalizeMeetingNotesCases(builder);
    addIntegrationsListCases(builder);
  }
});

export const {
  setToken,
  setMeetingDetails,
  incrementSocketOnMsgCount,
  setAddMeetingToggle,
  clearMeetingDetails,
  clearEMoneyDetails,
  clearNoteToSalesForce,
  clearActionItemsToSalesForce,
  startDownloading,
  endDownloading,
  setConsentEmailDetails
} = appSlice.actions;

export const selectToken = (state: RootState) => state.app.token;

export default appSlice.reducer;
