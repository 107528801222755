import { Box, Button, Modal, Typography } from "@mui/material";
import ColorsConst from "../../utils/ColorsConst";

function LogoutModal(props) {
  return (
    <Modal
      open={props.open}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
      }}
    >
      <Box
        sx={{
          bgcolor: ColorsConst.DARK_SURFACE_CONTAINER_HIGHEST,
          borderRadius: "16px",
          p: 2,
        }}
      >
        <Typography variant="h5" sx={{ color: ColorsConst.ON_SURFACE }}>
          Log out of Mili?
        </Typography>
        <Box mt="16px">
          <Button
            sx={{
              color: ColorsConst.DARK_PRIMARY,
              textTransform: "none",
              borderRadius: "32px",
              ml: 9,
            }}
            onClick={props.onSecondaryClick}
          >
            No, go back
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: ColorsConst.DARK_PRIMARY,
              color: ColorsConst.ON_PRIMARY,
              textTransform: "none",
              borderRadius: "32px",
              ml: 1,
              "&:hover": {
                backgroundColor: ColorsConst.DARK_PRIMARY,
              },
            }}
            onClick={props.onPrimaryClick}
          >
            Yes, log out
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default LogoutModal;
