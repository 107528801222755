import CircularProgress from "@mui/material/CircularProgress";
import ColorsConst from "../../utils/ColorsConst";

function Loader() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        padding: "16px",
      }}
    >
      <CircularProgress sx={{ color: ColorsConst.DARK_PRIMARY }} />
    </div>
  );
}

export default Loader;
