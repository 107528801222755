import { useState, useRef, useEffect } from "react";
import StartReconnectedAudio from "../assets/audio/StartReconnected.mp3";
import StopAudio from "../assets/audio/Alert_Error_Normal.mp3";
import ErrorAudio from "../assets/audio/Alert_Error_High.mp3";
import MeetingTimerAudio from "../assets/audio/Alert_Error_Normal.mp3";
import ConsentAudio from "../assets/audio/EvergreenGavekalAudioCue.mp3";
import ConsentAudioTrilogy from "../assets/audio/TrilogyFinancialAudioCue.mp3";

// Define the shape of the hook return type
interface UseAudio {
  isPlaying: boolean;
  hasError: boolean;
  play: (url: string) => void; // Pass the URL of the audio file to play
  stop: () => void;
}

export const AUDIO_PATH = {
  START: StartReconnectedAudio,
  STOP: StopAudio,
  ERROR: ErrorAudio,
  MEETING_TIMER: MeetingTimerAudio,
  // CONSENT: ConsentAudio,
  CONSENT: ConsentAudioTrilogy,
};

const useAudio = (): UseAudio => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [hasError, setHasError] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    // Clean up event listeners when the component unmounts
    return () => {
      if (audioRef.current) {
        audioRef.current.pause(); // Ensure the audio is stopped
        audioRef.current = null; // Cleanup the reference
      }
    };
  }, []);

  const play = (url: string) => {
    // Pause any currently playing audio before playing a new one
    if (audioRef.current) {
      audioRef.current.pause();
    }

    // Create a new Audio object with the provided URL
    audioRef.current = new Audio(url);

    // Reset error state
    setHasError(false);

    // Add event listeners for the new audio object
    const handleAudioEnded = () => {
      setIsPlaying(false);
    };

    const handleAudioError = () => {
      setHasError(true);
      setIsPlaying(false);
    };

    if (audioRef.current) {
      audioRef.current.addEventListener("ended", handleAudioEnded);
      audioRef.current.addEventListener("error", handleAudioError);
      audioRef.current
        .play()
        .then(() => {
          setIsPlaying(true);
        })
        .catch((error) => {
          console.error("Error playing audio:", error);
          setHasError(true);
        });
    }
  };

  const stop = () => {
    if (audioRef.current && isPlaying) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0; // Reset the audio to the beginning
      setIsPlaying(false);
    }
  };

  return { isPlaying, hasError, play, stop };
};

export default useAudio;
