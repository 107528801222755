import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { RootState } from "../redux/store";

interface PrivateRouteType {
  Component: any;
}

const PrivateRoute = ({ Component }: PrivateRouteType) => {
  // dont remove this line for now + dont comment out as well
  const token = useSelector((state: RootState) => state.app.token);
  const userOnboard = token && localStorage.getItem("user_onboard");

  const location = useLocation();

  return userOnboard ? (
    Component
  ) : (
    <Navigate
      to={`/login${location.search ? `${location.search}&redirect=${location.pathname}` : `?redirect=${location.pathname}`}`}
    />
  );
};
export default PrivateRoute;
