import { Typography } from "@mui/material";
import ColorsConst from "../../utils/ColorsConst";
import { useDeviceStatus } from "../../hooks/useDeviceStatus";

const M3HeadlineLarge = ({ children, ...props }) => {
  const { isSmallDevice } = useDeviceStatus();

  return (
    <Typography
      fontSize={isSmallDevice ? "22px" : "32px"}
      lineHeight={isSmallDevice ? "28px" : "40px"}
      color={ColorsConst.ON_SURFACE}
      {...props}
    >
      {children}
    </Typography>
  );
};

export default M3HeadlineLarge;
