import {
  MEETING_LIST_FETCH_LIMIT,
  SHARED_MEETING_LIST_FETCH_LIMIT
} from "../utils/Const";
import { getDeviceType, timezoneForBE } from "../utils/Util";

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // Set your base URL here
export const SOCKET_BASE_URL = process.env.REACT_APP_SOCKET_BASE_URL;
export const REACT_APP_AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
export const REACT_APP_AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
export const REACT_APP_AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;
export const REACT_APP_PUBLIC_POSTHOG_KEY =
  process.env.REACT_APP_PUBLIC_POSTHOG_KEY;
export const REACT_APP_PUBLIC_POSTHOG_HOST =
  process.env.REACT_APP_PUBLIC_POSTHOG_HOST;

const API_VERSION = "/api/1.0";

export const ROUTES = {
  // Analytics dashboard
  GET_BUSINESS_OUTCOME: `${API_VERSION}/advisor/analytics/business-outcome`,
  GET_ADVISORS: `${API_VERSION}/advisor/analytics/meeting-analytics/advisors`,
  GET_TEAMS: `${API_VERSION}/advisor/analytics/teams`,
  GET_CLIENT_INSIGHTS: `${API_VERSION}/advisor/analytics/client-insights/summarised`,
  GET_CLIENT_GIFTS: `${API_VERSION}/advisor/analytics/client-gifts`,
  GET_CLIENT_SCORES: `${API_VERSION}/advisor/analytics/team-meeting-scores`,
  GET_CLIENT_INSIGHTS_DOWNLOAD: `${API_VERSION}/advisor/analytics/client-insights/download`,
  GET_FUNNEL_STAGE_ANALYTICS: `${API_VERSION}/advisor/analytics/funnel-stage-analytics`,
  GET_TABLE_DATA: `${API_VERSION}/advisor/analytics/meeting-analytics/data`,
  GET_KPI_DATA_FOR_ANALYTICS_DASHBOARD: `${API_VERSION}/advisor/analytics/meeting-analytics/kpi-data`,
  GET_KPI_DATA_FOR_ADVISOR_PERFORMANCE: `${API_VERSION}/advisor/analytics/advisor-performance/kpi-data`,
  GET_MEETING_BREAKDOWN_FOR_ADVISOR_PERFORMANCE: `${API_VERSION}/advisor/analytics/advisor-performance/meeting-breakdown`,
  GET_REFERRALS_FOR_ADVISOR_PERFORMANCE: `${API_VERSION}/advisor/analytics/advisor-performance/referrals`,
  GET_MEETING_SCORES_FOR_ADVISOR_PERFORMANCE: `${API_VERSION}/advisor/analytics/advisor-performance/meeting-scores`,
  GET_MEETING_HIGHLIGHTS_FOR_ADVISOR_PERFORMANCE: `${API_VERSION}/advisor/analytics/advisor-performance/meeting-highlights`,

  GET_MEETING_PREP: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/meeting-prep`,
  UPDATE_MEETING_PREP_BLOCK_NOTES: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/meeting-prep/update`,

  GET_SCRATCHPAD_NOTES: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/get-scratchpad-notes`,
  UPDATE_SCRATCHPAD_NOTES: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/update-scratchpad-notes`,
  UPDATE_NOTES_BLOCK_NOTES: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/update-notes-block-note`,
  UPDATE_SUMMARY_BLOCK_NOTES: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/update-summary-block-note`,
  UPDATE_FOLLOWUP_EMAIL_BLOCK_NOTES: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/update-follow-up-email-block-note`,
  GET_WEALTHBOX_WORKFLOWS_TEMPLATES: `${API_VERSION}/advisor/wealthbox/workflows/template/list`,
  GET_WEALTHBOX_CATEGORIES: `${API_VERSION}/advisor/wealthbox/categories/list`,
  GET_WEALTHBOX_USERGROUPS: `${API_VERSION}/advisor/wealthbox/user-groups/list`,
  GET_WEALTHBOX_CONTACTS: `${API_VERSION}/advisor/wealthbox/contact/list`,
  SYNC_WEALTHBOX_CONTACTS: `${API_VERSION}/advisor/wealthbox/contact/sync`,
  SYNC_STATUS_WEALTHBOX_CONTACTS: `${API_VERSION}/advisor/wealthbox/contact/sync-status`,
  GET_WEALTHBOX_TAGS: `${API_VERSION}/advisor/wealthbox/tags/list`,
  GET_WEALTHBOX_ASSIGNEE: `${API_VERSION}/advisor/wealthbox/assignee/list`,
  ADD_WEALTHBOX_NOTES: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/wealthbox/add-notes`,
  WEALTHBOX_NOTES_EXISTS: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/wealthbox/notes/exists`,
  ADD_WEALTHBOX_ACTION_ITEM: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/wealthbox/add-action-item`,
  GET_WEALTHBOX_ACTION_ITEMS: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/wealthbox/generate-action-items`,
  DELETE_WEALTHBOX_WORKFLOWS: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/wealthbox/workflows/delete`,
  CREATE_WEALTHBOX_WORKFLOWS: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/wealthbox/workflows/create`,
  GET_WEALTHBOX_WORKFLOWS: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/wealthbox/workflows/list`,
  SET_ADVYZON_EXTENSION_DATA: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/advyzon/store-meeting-data`,
  GET_ADVYZON_EXTENSION_DATA: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/advyzon/get-meeting-data`,
  GET_ADVYZON_CONTACTS: `${API_VERSION}/advisor/integration/advyzon/contacts/list`,
  GET_ADVYZON_ACTION_ITEMS: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/advyzon/generate-action-items`,

  REPORT_BUG: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/advyzon/report-issue`,

  GET_XLR8_CONTACTS: `${API_VERSION}/advisor/xlr8/contact/list`,
  GET_XLR8_ENTITIES: `${API_VERSION}/advisor/xlr8/entity/list`,
  GET_XLR8_ASSIGNEE: `${API_VERSION}/advisor/xlr8/assignee/list`,
  GET_XLR8_QUEUE: `${API_VERSION}/advisor/xlr8/queue/list`,
  SYNC_XLR8_ENTITIES: `${API_VERSION}/advisor/xlr8/sync/contacts`,
  SYNC_STATUS_XLR8_ENTITIES: `${API_VERSION}/advisor/xlr8/sync/status`,
  ADD_XLR8_NOTES: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/xlr8/add-notes`,
  XLR8_NOTES_EXISTS: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/xlr8/notes/exists`,
  XLR8_ACTION_EXISTS: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/xlr8/action-items/exists`,
  ADD_XLR8_ACTION_ITEM: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/xlr8/add-action-item`,
  GET_XLR8_ACTION_ITEMS: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/xlr8/generate-action-items`,

  GET_PRACTIFI_CONTACTS: `${API_VERSION}/advisor/practifi/contact/list`,
  GET_PRACTIFI_ENTITIES: `${API_VERSION}/advisor/practifi/entity/list`,
  GET_PRACTIFI_ASSIGNEE: `${API_VERSION}/advisor/practifi/assignee/list`,
  SYNC_PRACTIFI_ENTITIES: `${API_VERSION}/advisor/practifi/sync/contacts`,
  GET_PRACTIFI_QUEUE: `${API_VERSION}/advisor/practifi/queue/list`,
  SYNC_STATUS_PRACTIFI_ENTITIES: `${API_VERSION}/advisor/practifi/sync/status`,
  ADD_PRACTIFI_NOTES: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/practifi/add-notes`,
  PRACTIFI_NOTES_EXISTS: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/practifi/notes/exists`,
  GET_PRACTIFI_NOTES: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/practifi/note-details`,
  GET_PRACTIFI_OBJECTS_DATA: ({ objects = "", fields = "", search = "" }) =>
    `${API_VERSION}/advisor/practifi/object-data?object=${objects}&fields=${fields}&search=${search}`,
  PRACTIFI_ACTION_EXISTS: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/practifi/action-items/exists`,
  ADD_PRACTIFI_ACTION_ITEM: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/practifi/add-action-item`,
  GET_PRACTIFI_ACTION_ITEMS: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/practifi/generate-action-items`,

  GET_SALESFORCE_CONTACTS: `${API_VERSION}/advisor/salesforce/contact/list`,
  GET_SALESFORCE_ENTITIES: `${API_VERSION}/advisor/salesforce/entity/list`,
  GET_SALESFORCE_ASSIGNEE: `${API_VERSION}/advisor/salesforce/assignee/list`,
  SYNC_SALESFORCE_ENTITIES: `${API_VERSION}/advisor/salesforce/sync/contacts`,
  GET_SALESFORCE_QUEUE: `${API_VERSION}/advisor/salesforce/queue/list`,
  SYNC_STATUS_SALESFORCE_ENTITIES: `${API_VERSION}/advisor/salesforce/sync/status`,
  ADD_SALESFORCE_NOTES: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/salesforce/add-notes`,
  SALESFORCE_NOTES_EXISTS: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/salesforce/notes/exists`,
  GET_SALESFORCE_NOTES: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/salesforce/note-details`,
  GET_SALESFORCE_OBJECTS_DATA: ({ objects = "", fields = "", search = "" }) =>
    `${API_VERSION}/advisor/salesforce/object-data?object=${objects}&fields=${fields}&search=${search}`,
  SALESFORCE_ACTION_EXISTS: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/salesforce/action-items/exists`,
  ADD_SALESFORCE_ACTION_ITEM: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/salesforce/add-action-item`,
  GET_SALESFORCE_ACTION_ITEMS: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/salesforce/generate-action-items`,

  GET_SALESFORCE_FINANCIAL_CLOUD_CONTACTS: `${API_VERSION}/advisor/sfc/contact/list`,
  GET_SALESFORCE_FINANCIAL_CLOUD_ENTITIES: `${API_VERSION}/advisor/sfc/entity/list`,
  GET_SALESFORCE_FINANCIAL_CLOUD_ASSIGNEE: `${API_VERSION}/advisor/sfc/assignee/list`,
  SYNC_SALESFORCE_FINANCIAL_CLOUD_ENTITIES: `${API_VERSION}/advisor/sfc/sync/contacts`,
  GET_SALESFORCE_FINANCIAL_CLOUD_QUEUE: `${API_VERSION}/advisor/sfc/queue/list`,
  SYNC_STATUS_SALESFORCE_FINANCIAL_CLOUD_ENTITIES: `${API_VERSION}/advisor/sfc/sync/status`,
  ADD_SALESFORCE_FINANCIAL_CLOUD_NOTES: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/sfc/add-notes`,
  SALESFORCE_FINANCIAL_CLOUD_NOTES_EXISTS: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/sfc/notes/exists`,
  GET_SALESFORCE_FINANCIAL_CLOUD_NOTES: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/sfc/note-details`,
  GET_SALESFORCE_FINANCIAL_CLOUD_OBJECTS_DATA: ({
    objects = "",
    fields = "",
    search = ""
  }) =>
    `${API_VERSION}/advisor/sfc/object-data?object=${objects}&fields=${fields}&search=${search}`,
  SALESFORCE_FINANCIAL_CLOUD_ACTION_EXISTS: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/sfc/action-items/exists`,
  ADD_SALESFORCE_FINANCIAL_CLOUD_ACTION_ITEM: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/sfc/add-action-item`,
  GET_SALESFORCE_FINANCIAL_CLOUD_ACTION_ITEMS: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/sfc/generate-action-items`,

  GET_SALENTICA_CONTACTS: `${API_VERSION}/advisor/salentica/contact/list`,
  GET_SALENTICA_ENTITIES: `${API_VERSION}/advisor/salentica/entity/list`,
  GET_SALENTICA_ASSIGNEE: `${API_VERSION}/advisor/salentica/assignee/list`,
  SYNC_SALENTICA_ENTITIES: `${API_VERSION}/advisor/salentica/sync/contacts`,
  GET_SALENTICA_QUEUE: `${API_VERSION}/advisor/salentica/queue/list`,
  SYNC_STATUS_SALENTICA_ENTITIES: `${API_VERSION}/advisor/salentica/sync/status`,
  ADD_SALENTICA_NOTES: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/salentica/add-notes`,
  SALENTICA_NOTES_EXISTS: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/salentica/notes/exists`,
  GET_SALENTICA_NOTES: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/salentica/note-details`,
  GET_SALENTICA_OBJECTS_DATA: ({ objects = "", fields = "", search = "" }) =>
    `${API_VERSION}/advisor/salentica/object-data?object=${objects}&fields=${fields}&search=${search}`,
  SALENTICA_ACTION_EXISTS: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/salentica/action-items/exists`,
  ADD_SALENTICA_ACTION_ITEM: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/salentica/add-action-item`,
  GET_SALENTICA_ACTION_ITEMS: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/salentica/generate-action-items`,

  SEND_CONSENT_EMAIL: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/send-consent-email`,
  GET_INTEGRATION_LIST: `${API_VERSION}/advisor/integration/list`,
  USER_PROFILE: `${API_VERSION}/advisor/user-profile`,
  USER_PROFILE_UPDATE: `${API_VERSION}/advisor/user-profile/update`,
  CLIENT_LIST: `${API_VERSION}/advisor/client/list`,
  CLIENT_DETAILS: (clientId) => `${API_VERSION}/advisor/client/${clientId}`,
  UPDATE_CLIENT: (clientId) =>
    `${API_VERSION}/advisor/client/${clientId}/update`,
  TEAM_MEMBER_LIST: `${API_VERSION}/advisor/org-advisors/list`,
  MEETING_LIST: (offset) =>
    `${API_VERSION}/advisor/meeting/list?offset=${offset}&limit=10`,
  SHARED_MEETING_LIST: (
    user_id,
    offset,
    client_ids = [],
    filter_month = "",
    filter_year = "",
    team_member_ids = []
  ) =>
    `${API_VERSION}/advisor/meeting/shared/list-v2?user_id=${user_id}&offset=${offset}&client_ids=${client_ids}&filter_month=${filter_month}&filter_year=${filter_year}&team_member_ids=${team_member_ids}&limit=${SHARED_MEETING_LIST_FETCH_LIMIT}`,
  MEETING_LIST_V2: (
    user_id,
    cursor_time = "",
    client_ids = [],
    filter_month = "",
    filter_year = ""
  ) =>
    `${API_VERSION}/advisor/meeting/list-v2?user_id=${user_id}&cursor=${cursor_time}&client_ids=${client_ids}&filter_month=${filter_month}&filter_year=${filter_year}`,
  MEETING_LIST_V3: (
    start_date = "",
    end_date = "",
    client_ids = [],
    team_member_ids = [],
    offset,
    shared_type,
    section,
    search_term
  ) => {
    if (start_date) {
      start_date = new Date(start_date).toISOString();
      end_date = new Date(end_date).toISOString();
    }
    return `${API_VERSION}/advisor/meeting/list-v3?end_date=${end_date}&start_date=${start_date}&shared_type=${shared_type}&section=${section}&client_ids=${client_ids}&team_member_ids=${team_member_ids}&offset=${offset}&search_term=${search_term}&limit=${MEETING_LIST_FETCH_LIMIT}`;
  },
  CLIENT_MEETING_LIST: (clientId) =>
    `${API_VERSION}/advisor/client/${clientId}/meeting/list`,
  CREATE_CLIENT: `${API_VERSION}/advisor/client/create`,
  MEETING_DETAILS: (meetingId) => `${API_VERSION}/advisor/meeting/${meetingId}`,
  WS_SOCKET: (meetingId, token) =>
    `${SOCKET_BASE_URL}/ws/1.0/advisor/meeting/${meetingId}/ws/connect?code=${token}&deviceType=${getDeviceType()}&timezone=${timezoneForBE()}`,
  MEETING_UPDATE: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/update`,
  MEETING_CREATE: `${API_VERSION}/advisor/meeting/create`,
  CONTACT_LIST: `${API_VERSION}/advisor/client/list`,
  ADVISORS_LIST: `${API_VERSION}/advisor/org-advisors/list`,
  MEETING_TYPE_LIST: `${API_VERSION}/advisor/meeting-type/list`,
  USER_INTERACTION: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/user-interaction/update`,
  REGISTER_CALL_AI_ADVISOR: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/register-call-for-ai-advisor`,
  REGISTER_CALL_AI_CLIENT: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/register-call-for-ai-client`,
  SHARE_DOWNLOADED_NOTES: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/share-downloaded-notes`,
  DOWNLOAD_NOTES: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/download-notes`,
  DOWNLOAD_NOTES_NEW: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/download-notes-new`,
  APP_VERSION: `${API_VERSION}/app-version`,
  DELETE_MEETING: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/delete`,
  GET_EMONEY_DETAILS: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/integration/get-emoney-data`,
  CALENDAR_EXISTS: `${API_VERSION}/advisor/calendar/exists`,
  SYNC_CALENDER: `${API_VERSION}/advisor/calendar/sync`,
  MEETING_TYPES_LIST: `${API_VERSION}/advisor/meeting-type/list`,
  UPDATE_MEETING_TYPE: (meeting_id) =>
    `${API_VERSION}/advisor/meeting/${meeting_id}/update-meeting-type`,
  CONNECT_CALENDAR: (token, redirect_uri) =>
    `${API_BASE_URL}${API_VERSION}/integration/microsoft?token=${token}&mili_redirect_url=${redirect_uri}`,
  //share
  GET_SHARED_LIST: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/shared-users/list`,
  GET_SHAREABLE_LIST: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/shareable-users/list`,
  UPDATE_MEETING_SHARE_LIST: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/update/share`,

  FINALIZE_MEETING_NOTES: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/finalize-notes`,
  ORGANIZATION_DETAILS: `${API_VERSION}/advisor/organization-details`,
  //auth
  SEND_OTP: `${API_VERSION}/auth/send-otp`,
  VERIFY_OTP: `${API_VERSION}/auth/verify-otp`,
  REFRESH_TOKEN: `${API_VERSION}/auth/refresh-token`,
  LOGOUT: `https://${REACT_APP_AUTH0_DOMAIN}/v2/logout?client_id=${REACT_APP_AUTH0_CLIENT_ID}`,
  LIVEBLOCKS_AUTH: (meetingId: string) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/liveblocks-auth`,

  // ios apis - upload
  GET_UPLOAD_URL: (meetingId) =>
    `${API_BASE_URL}${API_VERSION}/advisor/meeting/${meetingId}/get-upload-url`,
  ATTACH_MEETING_RECORDING: (meetingId) =>
    `${API_BASE_URL}${API_VERSION}/advisor/meeting/${meetingId}/attach-meeting-recording`,
  START_PROCESSING: (meetingId) =>
    `${API_BASE_URL}${API_VERSION}/advisor/meeting/${meetingId}/start-processing`,

  ADD_NOTES: (meetingId, integrationName, integrationType) =>
    `${API_BASE_URL}${API_VERSION}/advisor/meeting/${meetingId}/integration/${integrationName}/${integrationType}/add-notes`,
  GET_NOTES: (meetingId, integrationType) =>
    `${API_BASE_URL}${API_VERSION}/advisor/meeting/${meetingId}/integration/${integrationType}/object/get-note-data-with-related-records`,
  CHECK_NOTES_EXISTS: (meetingId, integrationName, integrationType) =>
    `${API_BASE_URL}${API_VERSION}/advisor/meeting/${meetingId}/integration/${integrationName}/${integrationType}/notes/exists`,
  ADD_ACTION_ITEMS: (meetingId, integrationName, integrationType) =>
    `${API_BASE_URL}${API_VERSION}/advisor/meeting/${meetingId}/integration/${integrationName}/${integrationType}/add-action-items`,
  GENERATE_ACTION_ITEMS: (meetingId, integrationName, integrationType) =>
    `${API_BASE_URL}${API_VERSION}/advisor/meeting/${meetingId}/integration/${integrationName}/${integrationType}/generate-action-items`,
  GET_OBJECTS_DATA: (
    integrationType,
    { objects = "", fields = "", search = "" }
  ) =>
    `${API_BASE_URL}${API_VERSION}/advisor/integration/${integrationType}/object/search?object=${objects}&fields=${fields}&search=${search}`
};
