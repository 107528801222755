import { useEffect } from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import Router from "./router";
import { setToken } from "./redux/reducers/appSlice";
import {
  fetchUserProfile,
  fetchOrganizationDetails
} from "./redux/actions/appActions";
import { SocketProvider } from "./context/SocketContext";
import { useToast } from "./components/toast/useToast";
import { toastEmitter } from "./components/toast/toastManager";
import {
  chromeVariableAvailable,
  injectMicrophonePermissionIframe,
  isExtension
} from "./utils/Util";
import { LicenseInfo } from "@mui/x-license";

LicenseInfo.setLicenseKey(
  "3437b30f2977dc8610648a01c2d334e8Tz0xMDIxMjcsRT0xNzYzNzE4ODI0MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y"
);

// if (window.location.protocol === "chrome-extension:") {
//   injectMicrophonePermissionIframe();
// }

// Check if token exists in localStorage and dispatch setToken action if it does

const App = () => {
  const ToastComponent = useToast();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      store.dispatch(fetchUserProfile());
      store.dispatch(fetchOrganizationDetails());
    }
  }, []);

  useEffect(() => {
    const messageListener = (message, sender, sendResponse) => {
      if (message.action === "processCompleted") {
        if (
          message.taskName === "ADVYZON_ADD_ACTION_ITEMS" ||
          message.taskName === "ADVYZON_ADD_NOTES" ||
          message.taskName === "ADVYZON"
        ) {
          return;
        }
        showSuccess(message.taskName + "  has been completed.");
      }
    };

    try {
      if (chromeVariableAvailable()) {
        chrome?.runtime?.onMessage?.addListener(messageListener);
      }
    } catch (err) {
      console.log("chrome err:", err);
    }

    // Cleanup the listener when the component unmounts
    return () => {
      try {
        chrome?.runtime?.onMessage?.removeListener(messageListener);
      } catch (err) {
        console.log("chrome err:", err);
      }
    };
  }, []);

  const showSuccess = (message) => {
    toastEmitter.show({ message: message, severity: "success" });
  };

  return (
    <Provider store={store}>
      <SocketProvider>
        <Router />
      </SocketProvider>
      {ToastComponent}
    </Provider>
  );
};

export default App;
