import {
  Box,
  ButtonBase,
  Checkbox,
  CircularProgress,
  IconButton,
  Popover,
  Typography
} from "@mui/material";
import M3TitleLarge from "../../../../components/customTypography/M3TitleLarge";
import { useEffect, useRef, useState } from "react";
import { ROUTES } from "../../../../api/config";
import { get, post } from "../../../../api";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import CloseIcon from "@mui/icons-material/Close";
import M3BodyMediumTypography from "../../../../components/customTypography/M3BodyMedium";
import ColorsConst from "../../../../utils/ColorsConst";
import { useDeviceStatus } from "../../../../hooks/useDeviceStatus";
import M3LabelLargeTypography from "../../../../components/customTypography/M3LabelLargeTypography";
import {
  EXTENSION_CTA_STATES_LIST,
  isChromeExtension,
  markdownToPlainTextBeautify
} from "../../../../utils/Util";
import M3TitleMedium from "../../../../components/customTypography/M3TitleMedium";
import ReplayIcon from "@mui/icons-material/Replay";
import DoneIcon from "@mui/icons-material/Done";
import M3LabelMedium from "../../../../components/customTypography/M3LabelMedium";

const AdvyzonExtension = ({
  onClose,
  notesBlockContainerRef,
  meetingDetails,
  notesBlockHtml,
  summaryBlockHtml,
  extNotes = [],
  extActionItems = [],
  ctaState,
  renderReportAnIssueCta,
  triggerResumeRetryFlow
}) => {
  const inChromeExt = !!isChromeExtension();
  const [loadingActionItemsState, setLoadingActionItemsState] = useState(
    inChromeExt ? "COMPLETED" : "STARTED"
  );
  const pollingIntervalRef = useRef(null);
  const isMountedRef = useRef(false);
  const params = useParams();
  const meetingId = params.meetingId;

  const { isSmallDevice } = useDeviceStatus();

  const [isPolling, setIsPolling] = useState(false);

  const [actionItemsList, setActionItemsList] = useState(
    inChromeExt ? extActionItems : []
  );
  const [notesList, setNotesList] = useState(
    inChromeExt
      ? extNotes
      : [
          {
            id: uuidv4(),
            name: meetingDetails.title,
            description: `<p>${meetingDetails.title}</p>${summaryBlockHtml}${notesBlockHtml}`,
            checked: true,
            type: "NOTE"
          }
        ]
  );

  const getCtaText = () => {
    if (ctaState === EXTENSION_CTA_STATES_LIST.SYCNING || isPolling) {
      return "Syncing";
    } else if (ctaState === EXTENSION_CTA_STATES_LIST.WAITING_FOR_LOGIN) {
      return "Waiting for Advyzon Login";
    } else if (ctaState === EXTENSION_CTA_STATES_LIST.COMPLETE) {
      return "Sync complete";
    } else if (ctaState === EXTENSION_CTA_STATES_LIST.RETRY) {
      return "Retry";
    } else if (ctaState === EXTENSION_CTA_STATES_LIST.PAUSED) {
      return "Resume sync";
    } else if (!ctaState) {
      return "Sync to Advyzon";
    }
  };

  useEffect(() => {
    if (inChromeExt) {
      setNotesList(extNotes);
    }
  }, [extNotes]);

  useEffect(() => {
    if (inChromeExt) {
      setActionItemsList(extActionItems);
    }
  }, [extActionItems]);

  const renderHeading = (text) => {
    return (
      <Typography
        sx={{
          fontSize: "18px",
          mb: "8px",
          fontWeight: 500,
          lineHeight: "24px",
          letterSpacing: "0.15px",
          color: ColorsConst.ON_SURFACE
        }}
      >
        {text}
      </Typography>
    );
  };

  function convertDate(dateStr) {
    let [day, month, year] = dateStr.split("/");
    return `${month.padStart(2, "0")}/${day}/${year}`;
  }

  const generateActionItems = async () => {
    try {
      const takingLongerStateRef = setTimeout(() => {
        setLoadingActionItemsState("TAKING_LONGER");
      }, 60 * 1000);

      const savedData = await get(ROUTES.GET_ADVYZON_EXTENSION_DATA(meetingId));

      if (savedData?.note?.syncStatus === "SYNCED") {
        setNotesList([{ ...savedData?.note }]);
      }

      if (savedData?.action_items?.find((d) => d.syncStatus === "SYNCED")) {
        setActionItemsList([...savedData?.action_items]);
      } else {
        const res = await post(
          ROUTES.GET_ADVYZON_ACTION_ITEMS(meetingId),
          {
            html_notes: markdownToPlainTextBeautify(notesBlockContainerRef)
          },
          null,
          { timeout: 120 * 1000 }
        );

        const newList = res.action_items.map((v, i) => {
          return {
            id: v.id || uuidv4(),
            subject: v.subject || "",
            comment: v.comment || "",
            due_date: v.due_date
              ? convertDate(new Date(v.due_date).toLocaleDateString())
              : null,
            checked: true,
            type: "ACTION_ITEM"
          };
        });

        setActionItemsList(newList);
      }
      clearTimeout(takingLongerStateRef);

      setLoadingActionItemsState("COMPLETED");
    } catch (e) {
      console.error(e, "generateActionItems Advyzon");
      setLoadingActionItemsState("FAILED");
    }
  };

  useEffect(() => {
    if (!inChromeExt) {
      generateActionItems();
    }
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
      clearTimeout(pollingIntervalRef.current);
      setIsPolling(false);
    };
  }, []);

  const handleCheckUnCheck = (item, type) => {
    const data = type === "NOTE" ? notesList : actionItemsList;
    const newList = data.map((d) => {
      if (item.id === d.id) {
        d.checked = !d.checked;
      }
      return d;
    });
    if (type === "NOTE") {
      setNotesList(newList);
    } else if (type === "ACTION_ITEM") {
      setActionItemsList(newList);
    }
  };

  // const startPolling = async () => {
  //   try {
  //     if (!isPolling) {
  //       setIsPolling(true);
  //     }
  //     if (!isMountedRef.current) {
  //       setIsPolling(false);
  //       return;
  //     }
  //     clearTimeout(pollingIntervalRef.current);
  //     const res = await get(ROUTES.GET_ADVYZON_EXTENSION_DATA(meetingId));
  //     setNotesList([{ ...res.note }]);
  //     setActionItemsList([...res.action_items]);
  //     // pollingIntervalRef.current = setTimeout(() => {
  //     //   startPolling();
  //     // }, 5000);
  //   } catch (e) {
  //     console.error("startPolling ext", e);
  //   }
  // };

  const handleAddToAdvyzon = async () => {
    if (inChromeExt) {
      triggerResumeRetryFlow?.();
      return;
    }

    try {
      await post(ROUTES.SET_ADVYZON_EXTENSION_DATA(meetingId), {
        notes: notesList.map((k) => {
          if (k.syncStatus === "SYNCED") {
            return k;
          } else {
            return { ...k, syncStatus: "null" };
          }
        }),
        actionItems: actionItemsList.map((k) => {
          if (k.syncStatus === "SYNCED") {
            return k;
          } else {
            return { ...k, syncStatus: null };
          }
        })
      });
      window.open(`/extension/advyzon?meetingId=${meetingId}`, "_blank");
      setTimeout(() => {
        handleClose();
      });
      // startPolling();
    } catch (e) {
      console.error("handleAddToAdvyzon ext", e);
    }
  };

  const handleClose = () => {
    if (!inChromeExt) {
      onClose?.();
    }
  };

  const renderItemBox = (data, key, type) => {
    return (
      <Box key={data.id} mb="8px" display={"flex"} alignItems={"center"}>
        <Checkbox
          disabled={inChromeExt || isPolling || data.syncStatus === "SYNCED"}
          onClick={() => {
            handleCheckUnCheck(data, type);
          }}
          sx={{
            color: ColorsConst.ON_SURFACE_VARIANT,
            padding: "0px",
            mr: "8px",
            "&.Mui-disabled": { color: "rgb(108,104,116) !important" }
          }}
          checked={data.checked}
        />
        <M3BodyMediumTypography>{data[key]}</M3BodyMediumTypography>
        {data.syncStatus === "IN_PROGRESS" && (inChromeExt || isPolling) && (
          <CircularProgress
            size={16}
            sx={{ color: ColorsConst.DARK_TERTIARY, ml: "8px", flexShrink: 0 }}
          />
        )}
        {(data.syncStatus === "PAUSED" || data.syncStatus === "FAILED") &&
          (inChromeExt || isPolling) && (
            <ReplayIcon
              sx={{
                transform: "scaleX(-1)",
                color: ColorsConst.DARK_TERTIARY,
                height: "16px",
                width: "16px",
                ml: "8px",
                flexShrink: 0
              }}
            />
          )}
        {data.syncStatus === "SYNCED" && (
          <DoneIcon
            sx={{
              color: ColorsConst.DARK_TERTIARY,
              ml: "8px",
              height: "16px",
              width: "16px",
              flexShrink: 0
            }}
          />
        )}
      </Box>
    );
  };

  const isCtaEnabled = [...actionItemsList, ...notesList].find(
    (d) => d.checked === true && d.syncStatus != "SYNCED"
  );

  return (
    <Popover
      onClose={handleClose}
      open
      sx={{
        ".MuiBackdrop-root": {
          background: "rgba(0,0,0,0.75)"
        },
        ".MuiPaper-root": {
          bgcolor: ColorsConst.DARK_SURFACE_CONTAINER,
          overflowY: "auto",
          left: "50% !important",
          top: "50% !important",
          transform: "translate(-50%, -50%) !important",
          borderRadius: "16px",
          maxWidth: inChromeExt ? "unset" : "560px",
          maxHeight: inChromeExt ? "unset" : "500px",
          width: "100%",
          height: "100%"
        }
      }}
    >
      {loadingActionItemsState != "COMPLETED" && (
        <Box
          height={"100%"}
          bgcolor={ColorsConst.DARK_SURFACE_CONTAINER}
          borderRadius={"8px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {loadingActionItemsState == "STARTED" && (
            <Box textAlign={"center"}>
              <CircularProgress
                thickness={6}
                size={30}
                sx={{ color: ColorsConst.DARK_PRIMARY }}
              />
              <M3TitleMedium mt="24px">
                Loading Notes & Action items.
                <br />
                This may take a while
              </M3TitleMedium>
            </Box>
          )}
          {loadingActionItemsState == "TAKING_LONGER" && (
            <Box textAlign={"center"}>
              <CircularProgress
                thickness={6}
                size={30}
                sx={{ color: ColorsConst.DARK_PRIMARY }}
              />
              <M3TitleMedium mt="24px" mb="8px">
                Loading is taking longer than expected
              </M3TitleMedium>
              <Box
                display={"flex"}
                gap={"16px"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <ButtonBase
                  sx={{
                    p: "10px 12px"
                  }}
                  onClick={handleClose}
                >
                  <M3LabelLargeTypography color={ColorsConst.DARK_PRIMARY}>
                    Go back
                  </M3LabelLargeTypography>
                </ButtonBase>
                <ButtonBase
                  sx={{
                    p: "10px 12px"
                  }}
                  onClick={() => {
                    setLoadingActionItemsState("STARTED");
                  }}
                >
                  <M3LabelLargeTypography color={ColorsConst.DARK_PRIMARY}>
                    Keep waiting
                  </M3LabelLargeTypography>
                </ButtonBase>
              </Box>
            </Box>
          )}
          {loadingActionItemsState == "FAILED" && (
            <Box>
              <CircularProgress
                size={16}
                sx={{ color: ColorsConst.DARK_PRIMARY }}
              />
              <M3TitleMedium>Loding failed. Please retry</M3TitleMedium>
              <ButtonBase
                // sx={{
                // 	p: "10px 24px",
                // 	bgcolor: ColorsConst.DARK_PRIMARY,
                // 	width: "168px",
                // 	borderRadius: "100px",
                // }}
                onClick={generateActionItems}
              >
                <ReplayIcon
                  sx={{
                    transform: "scaleX(-1)",
                    color: ColorsConst.ON_PRIMARY,
                    height: "18px",
                    width: "18px",
                    mr: "8px"
                  }}
                />
                <M3LabelLargeTypography color={ColorsConst.ON_PRIMARY}>
                  Retry
                </M3LabelLargeTypography>
              </ButtonBase>
            </Box>
          )}
        </Box>
      )}
      {loadingActionItemsState === "COMPLETED" && (
        <>
          {inChromeExt && renderReportAnIssueCta?.()}
          <Box
            sx={{
              maxHeight: inChromeExt ? "calc(100vh - 88px)" : "unset",
              // overflow: "hidden auto",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              width: "100%",
              p: isSmallDevice
                ? inChromeExt
                  ? "16px 16px 72px 16px"
                  : "16px"
                : "24px"
            }}
          >
            <Box>
              <M3TitleLarge mb="16px">Add to Advyzon</M3TitleLarge>
              {!inChromeExt && (
                <IconButton
                  sx={{ position: "absolute", right: "16px", top: "16px" }}
                  onClick={handleClose}
                  size="small"
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Box>
            <Box
              sx={{
                overflow: "hidden auto"
              }}
            >
              <Box mb="16px">
                {renderHeading("Note")}
                {notesList.map((note) => renderItemBox(note, "name", "NOTE"))}
              </Box>
              <Box mb="16px">
                {renderHeading("Action items")}
                {actionItemsList.map((ai) =>
                  renderItemBox(ai, "subject", "ACTION_ITEM")
                )}
              </Box>
            </Box>
            {(!ctaState ||
              isPolling ||
              ctaState === EXTENSION_CTA_STATES_LIST.SYCNING ||
              ctaState === EXTENSION_CTA_STATES_LIST.WAITING_FOR_LOGIN) && (
              <ButtonBase
                disabled={!isCtaEnabled || inChromeExt || isPolling}
                sx={{
                  borderRadius: "100px",
                  width: "fit-content",
                  bgcolor:
                    !isCtaEnabled || inChromeExt || isPolling
                      ? "#E6E0E91F"
                      : "#4E00C2",
                  p: "10px 24px",
                  position: "relative",
                  left: "50%",
                  transform: "translateX(-50%)",
                  opacity: !isCtaEnabled || inChromeExt || isPolling ? 0.38 : 1,
                  color:
                    !isCtaEnabled || inChromeExt || isPolling
                      ? ColorsConst.ON_SURFACE
                      : ColorsConst.WHITE
                }}
                onClick={handleAddToAdvyzon}
              >
                {(ctaState || isPolling) && (
                  <CircularProgress
                    size={16}
                    sx={{ color: ColorsConst.ON_SURFACE, mr: "8px" }}
                  />
                )}
                <M3LabelLargeTypography>{getCtaText()}</M3LabelLargeTypography>
              </ButtonBase>
            )}
            {ctaState === EXTENSION_CTA_STATES_LIST.COMPLETE && (
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                sx={{ color: ColorsConst.DARK_PRIMARY }}
                py="10px"
              >
                <DoneIcon sx={{ height: "18px", width: "18px", mr: "8px" }} />
                <M3LabelLargeTypography color={ColorsConst.DARK_PRIMARY}>
                  Sync successful
                </M3LabelLargeTypography>
              </Box>
            )}

            {(ctaState === EXTENSION_CTA_STATES_LIST.PAUSED ||
              ctaState === EXTENSION_CTA_STATES_LIST.RETRY) && (
              <>
                <ButtonBase
                  sx={{
                    borderRadius: "100px",
                    bgcolor: "#4E00C2",
                    p: "10px 24px",
                    position: "relative",
                    left: "50%",
                    transform: "translateX(-50%)",
                    color: ColorsConst.WHITE,
                    mb: "8px",
                    width: "fit-content"
                  }}
                  onClick={handleAddToAdvyzon}
                >
                  {inChromeExt && ctaState && (
                    <ReplayIcon
                      sx={{
                        transform: "scaleX(-1)",
                        color: ColorsConst.WHITE,
                        height: "18px",
                        width: "18px",
                        mr: "8px"
                      }}
                    />
                  )}
                  <M3LabelLargeTypography>
                    {getCtaText()}
                  </M3LabelLargeTypography>
                </ButtonBase>
                <M3LabelMedium textAlign="center">
                  {ctaState === EXTENSION_CTA_STATES_LIST.PAUSED
                    ? "Sync is paused. Resume to continue"
                    : "Some items failed to sync. Please retry"}
                </M3LabelMedium>
              </>
            )}
          </Box>
        </>
      )}
    </Popover>
  );
};

export default AdvyzonExtension;
