import { Box, Button, Typography } from "@mui/material";
import ColorsConst from "../../utils/ColorsConst";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useEffect } from "react";

function ErrorFallback({ error }) {
  useEffect(() => {
    // Check if localStorage is available before proceeding
    // This is to prevent errors in environments where localStorage might not be accessible
    if (!localStorage?.getItem) return;

    const lastErrorTime = localStorage.getItem("last-error-time");
    if (!lastErrorTime) {
      localStorage.setItem("last-error-time", Date.now().toString());
      handleRefresh();
    }
  }, []);

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <Box
      sx={{
        // bgcolor: ColorsConst.DARK_SURFACE_CONTAINER_HIGH,
        p: 3,
        color: ColorsConst.ON_SURFACE,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h3"
        component="h1"
        gutterBottom
        color={ColorsConst.DARK_TERTIARY}
      >
        Oops!
      </Typography>
      <Typography
        variant="h5"
        component="h2"
        color={ColorsConst.DARK_ON_PRIMARY_CONTAINER}
        gutterBottom
      >
        Something went wrong.
      </Typography>
      <Typography
        variant="body1"
        color={ColorsConst.ON_SURFACE_VARIANT}
        paragraph
      >
        Please try refreshing Mili.
      </Typography>
      <Button
        variant="contained"
        sx={{
          mt: 2,
          bgcolor: ColorsConst.DARK_PRIMARY_CONTAINER,
          color: ColorsConst.DARK_ON_PRIMARY_CONTAINER,
          "&:hover": {
            background: ColorsConst.DARK_PRIMARY_CONTAINER,
          },
        }}
        startIcon={<RefreshIcon />}
        onClick={handleRefresh}
      >
        Refresh
      </Button>
    </Box>
  );
}

export default ErrorFallback;
