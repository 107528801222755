import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CloseButton = ({ onClose, sxProps = {} }) => {
  const { iconProps = {} } = sxProps;
  return (
    <IconButton
      sx={{ position: "absolute", right: "16px", top: "16px", ...sxProps }}
      onClick={onClose}
      size="small"
    >
      <CloseIcon {...iconProps} />
    </IconButton>
  );
};

export default CloseButton;
