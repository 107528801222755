import { useEffect, useRef } from "react";

// Define the type for the wake lock sentinel
interface WakeLockSentinel {
  release: () => Promise<void>;
}

const useWakeLock = () => {
  const wakeLockRef = useRef<WakeLockSentinel | null>(null);

  // Function to request the wake lock
  const requestWakeLock = async () => {
    try {
      if (document.visibilityState === "visible") {
        if ("wakeLock" in navigator) {
          wakeLockRef.current = await (navigator as any).wakeLock.request(
            "screen"
          );
          console.log("Screen wake lock acquired.");
        } else {
          console.log("Wake Lock API not supported by this browser.");
        }
      }
    } catch (err: any) {
      console.error(`${err.name}, ${err.message}`);
    }
  };

  // Function to release the wake lock
  const releaseWakeLock = async () => {
    if (wakeLockRef.current !== null) {
      try {
        await wakeLockRef.current.release();
        console.log("Screen wake lock released.");
        wakeLockRef.current = null;
      } catch (err: any) {
        console.error(`Error releasing wake lock: ${err.name}, ${err.message}`);
      }
    }
  };

  // Use effect to clean up the wake lock
  useEffect(() => {
    return () => {
      releaseWakeLock();
    };
  }, []);

  return { requestWakeLock, releaseWakeLock };
};

export default useWakeLock;
